import React, { useEffect, useState } from 'react';
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import Breadcrumb from 'component/Breadcrumb';
import CustomTable from 'component/CustomTable';
import LoadingOverlay from 'component/LoadingOverlay';
import PolicyDialog from 'component/PolicyDialog';
import useAuth from 'hooks/useAuth';
import { Link, useParams, useNavigate } from 'react-router-dom';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';

const QuoteView = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);
  const { companyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [customerOrg, setCustomerOrg] = useState([]);
  const [policy, setPolicy] = useState({});
  const [actionType, setActionType] = useState('');
  const [policyId, setPolicyId] = useState(null);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });
  const [quotes, setQuotes] = useState([]);
  const [open, setOpen] = useState(false);

  const quoteColumns = [
    {
      id: 'customerOrganization',
      label: 'Customer Organization'
    },
    {
      id: 'agentDisplayName',
      label: "Agent's Display Name"
    },
    {
      id: 'policyStatus',
      label: 'Policy Status'
    },
    {
      id: 'dateAdded',
      label: 'Date Added'
    }
  ];

  useEffect(() => {
    const getQuotes = async () => {
      setIsLoading(true);
      console.log('companyID', companyId);
      const result = await axios.get(`/api/policy/companyId/${companyId}`, {
        params: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          filter: 'Quote'
        }
      });
      console.log(result.data);

      setQuotes(result.data.policies);
      setPagination({ ...pagination, count: result.data.count });
      setIsLoading(false);
    };

    getQuotes();
  }, [pagination.rowsPerPage, pagination.page]);

  const handleEdit = (quote) => {
    console.log('POLICY', quote);
    setActionType('Edit');
    setPolicyId(quote.id);
    setPolicy({ ...quote });
    setOpen(true);
  };

  const handleDelete = (quote) => {
    setPolicyId(quote.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
  };
  const handleSave = async (policyDialog) => {
    try {
      const url = `/api/policy/${policyId ? `${policyId}/` : ''}companyId/${companyId}`;
      const actionHandlers = {
        Create: async () => {
          const response = await axios.post(url, policyDialog);
          handleGoToDetail(response.data);
        },
        Edit: async () => {
          const response = await axios.put(url, policyDialog);
          setQuotes((prevPolicies) => prevPolicies.map((policy) => (policy.id === policyId ? { ...policy, ...response.data } : policy)));
        },
        Delete: async () => {
          await axios.delete(url, { data: { declinedReason: policyDialog?.declinedReason || '' } });
          setQuotes((prevPolicies) => prevPolicies.filter((policy) => policy.id !== policyId));
          setPagination((prev) => ({ ...prev, page: 0, count: prev.count - 1 }));
          handleChangePage(null, 0);
        }
      };

      await actionHandlers[actionType]?.();
    } catch (error) {
      console.error('Error en handleSave:', error);
    } finally {
      handleClosedialog();
    }
  };

  const handleCopyPolicy = async (copyPolicy) => {
    const tempObj = {
      policy: {
        idCustomerOrganization: copyPolicy.idCustomerOrganization,
        idAgent: copyPolicy.idAgent,
        idParentPolicy: copyPolicy?.id,
        clientNameInsured: copyPolicy.clientNameInsured,
        endorsements: copyPolicy?.endorsements || '',
        idInsurance: copyPolicy?.idInsurance || null,
        insuranceNotes: copyPolicy?.insuranceNotes || '',
        beginDate: copyPolicy?.beginDate || '',
        endDate: copyPolicy?.endDate || '',
        idPolicyType: copyPolicy.idPolicyType,
        policyNumber: copyPolicy?.policyNumber || '',
        totalAnnualPremium: copyPolicy?.totalAnnualPremium,
        totalHullPremium: copyPolicy?.totalHullPremium,
        totalLiabilityPremium: copyPolicy?.totalLiabilityPremium,
        totalOtherPremium: copyPolicy?.totalOtherPremium,
        idPolicyStatus: copyPolicy?.idPolicyStatus,
        trainingRequired: copyPolicy.trainingRequired
      }
    };

    const response = await axios.post(`/api/policy/companyId/${companyId}`, {
      ...tempObj
    });
    setPolicy({ ...response.data });
    setPolicyId(response.data.id);
    setActionType('Edit');
    setPagination({ ...pagination, page: 0 });
    setOpen(true);
  };

  const handleGoToDetail = (policyDetail) => {
    navigate(`/company/${companyId}/policy/${policyDetail.idImmutable}`, {
      state: { customerOrg, users }
    });
  };

  useEffect(() => {
    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrg(result.data);
    };

    const getCompanyUserProfiles = async () => {
      const result = await axios.get(`/api/company/${companyId}/users`);
      setUsers(result.data.UserProfile);
    };

    getCustomerOrgs();
    getCompanyUserProfiles();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Quotes" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Quotes
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <PolicyDialog
            open={open}
            handleClosedialog={handleClosedialog}
            handlePolicySave={handleSave}
            customerOrg={customerOrg}
            actionType={actionType}
            policy={policy}
            users={users}
            companyId={companyId}
          />
        </Grid>
      </Grid>
      <CustomTable
        isLoading={isLoading}
        columns={quoteColumns}
        data={quotes}
        isSearch={true}
        hasClickableRows={true}
        title="Quotes"
        selectedRows={[]}
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={null}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Policy" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                  onClick={() => handleEdit(quotes[index])}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Policy" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                  onClik={() => handleDelete(quotes[index])}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Policy Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(quotes[index])}>
                <TextSnippetIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Policy">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleCopyPolicy(quotes[index])}>
                <ContentCopyTwoToneIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default QuoteView;
