import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';

// project import
import Breadcrumb from 'component/Breadcrumb';

// assets
import Google from 'assets/images/social-google.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import { useDispatch } from 'react-redux';
import * as actionTypes from 'store/actions';
import AlertDialogSlide from 'views/Elements/Advance/UIDialog/AlertDialogSlide';
import CompanyUserDialog from 'component/CompanyUserDialog';

// ==============================|| CONTACT LIST ||============================== //

const CompanyUsers = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const dispatchSnack = useDispatch();

  const columns = [
    {
      id: 'id',
      label: 'Id'
    },
    { id: 'displayName', label: 'Display Name' },
    { id: 'email', label: 'Email' },
    { id: 'list', label: 'Roles' }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roles: []
  });
  const [company, setCompany] = useState({});
  const [users, setUsers] = useState([]);
  const [actionType, setActionType] = useState('');
  const [error, setError] = useState({
    message: ''
  });
  const [userId, setUserId] = useState(null);
  const [openGoogleModal, setOpenGoogleModal] = useState(false);
  const { companyId } = useParams();
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  //const isAdmin = auth?.user['UserProfile_RoleAssignment']?.some((e) => e.Role.name === 'SuperAdmin') || false;
  const handleClosedialog = () => {
    setOpen(false);
    setOpenGoogleModal(false);
    setUser({});
  };

  const handleSave = async (dialog) => {
    const displayName = `${dialog?.firstName} ${dialog?.lastName}`;
    let flagError = false;
    switch (actionType) {
      case 'Create': {
        const response = await axios.post('/api/user', {
          userProfile: {
            idCompany: +companyId,
            displayName,
            email: dialog?.email,
            firstname: dialog?.firstName,
            lastname: dialog?.lastName
          },
          roles: dialog?.roles
        });
        if (response.data.message) {
          setError({ message: response.data.message });
          flagError = true;
        } else {
          const newUser = {
            ...response.data.userProfile,
            ['UserProfile_RoleAssignment']: response.data.userRoles
          };
          setUsers([...users, newUser]);
        }

        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/user/${userId}`, {
          userProfile: {
            displayName,
            email: dialog?.email,
            firstname: dialog?.firstName,
            lastname: dialog?.lastName
          },
          roles: dialog?.roles
        });
        if (response.data.message) {
          setError({ message: response.data.message });
          flagError = true;
        } else {
          const tempArray = [...users];
          const index = tempArray.findIndex((e) => e.id === userId);
          tempArray[index] = {
            ...response.data.userProfile,
            ['UserProfile_RoleAssignment']: response.data.userRoles
          };
          setUsers(tempArray);
        }

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/user/${userId}`);
        const tempArray = [...users];
        const index = tempArray.findIndex((e) => e.id === userId);
        tempArray.splice(index, 1);
        setUsers(tempArray);
        break;
      }
    }
    if (!flagError) {
      handleClosedialog();
    }
  };

  const handleEdit = (editUser) => {
    setUser({
      firstName: editUser.firstname,
      lastName: editUser.lastname,
      email: editUser.email,
      roles: editUser['UserProfile_RoleAssignment']?.map((e) => e.Role.id)
    });
    setActionType('Edit');
    setUserId(editUser.id);
    setOpen(true);
  };

  const handleDelete = (deleteUser) => {
    setUserId(deleteUser.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleInvite = async (inviteUser) => {
    const response = await axios.post('/api/user/reinvite', {
      email: inviteUser.email
    });

    if (response.status === 200) {
      dispatchSnack({
        type: actionTypes.SNACKBAR_OPEN,
        open: true,
        message: 'The account creation invite has been re-sent',
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
      });
    }
  };

  const handleUnLink = (googleUser) => {
    setUserId(googleUser.id);
    setOpenGoogleModal(true);
  };

  const handleUnlinkGoogle = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/user/${userId}/google/unlink`);
    const tempArray = [...users];
    const index = tempArray.findIndex((e) => e.id === userId);
    tempArray[index] = {
      ...response.data
    };
    setUsers(tempArray);
    if (userId === auth?.user?.id || userId === auth?.user?.user?.id) {
      await auth.loginWithData(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getCompanyUserProfiles = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/company/${companyId}/users`);
      setCompany(result.data);
      setUsers(result.data.UserProfile);
      setIsLoading(false);
    };

    getCompanyUserProfiles();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title={company.name + ' Users'} sx={{ mt: { xs: 2, lg: 0 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Users
        </Typography>
      </Breadcrumb>
      <AlertDialogSlide
        emitValue={userId}
        handleCloseDialog={handleClosedialog}
        open={openGoogleModal}
        message="Confirm if you want to unlink this users google account"
        handleResult={handleUnlinkGoogle}
        type="Google Unlink"
      />
      <LoadingOverlay loading={isLoading} />
      <CompanyUserDialog
        open={open}
        error={error}
        actionType={actionType}
        newUser={user}
        handleClose={handleClosedialog}
        handleSave={handleSave}
      />
      <CustomTable
        isLoading={isLoading}
        title="Users"
        data={users}
        columns={columns}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        isDisabled={!auth.writePrivledge(auth.user, 'UserProfile_Adm')}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit User" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'UserProfile_Adm')}
                  onClick={() => handleEdit(users[index])}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete User" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="delete"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'UserProfile_Adm')}
                  onClick={() => handleDelete(users[index])}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Invite" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Invite"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'UserProfile_Adm') || users[index]?.password?.length > 0}
                  onClick={() => handleInvite(users[index])}
                >
                  <EmailIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Unlink Google Account" placement="top">
              <span>
                <IconButton
                  variant="contained"
                  sx={{
                    mr: 0.5,
                    opacity: !users[index]?.idGoogle ? 0.4 : 1, // Apply opacity when disabled
                    pointerEvents: !users[index]?.idGoogle ? 'none' : 'auto', // Prevent interaction when disabled
                    '&:hover': {
                      opacity: !users[index]?.idGoogle ? 0.4 : 0.8 // Optional: Change hover effect when not disabled
                    }
                  }}
                  onClick={() => handleUnLink(users[index])}
                  disabled={!users[index]?.idGoogle}
                >
                  <img src={Google} alt="google" width="26px" height="26px" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default CompanyUsers;
