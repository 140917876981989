import {
  Grid,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Input,
  InputLabel,
  Select,
  DialogContentText
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { gridSpacing } from 'config.js';
import { useEffect, useState } from 'react';
import { Transition } from 'utils/transition';
import { validRegex } from 'utils/FormatUtils';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

const FormControlCustom = styled((props) => <FormControl {...props} />)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
  maxWidth: 500,
  width: '100%'
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const CompanyUserDialog = ({ open, handleSave, handleClose, actionType, newUser, error }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [user, setUser] = useState({});
  const [listOfRoles, setListOfRoles] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    setUser({
      ...user,
      [event.target.name]: value
    });
  };
  const handleClosedialog = () => {
    setUser({});
    handleClose();
  };

  const handleUserSave = () => {
    handleSave(user);
  };

  useEffect(() => {
    if (Object.values(newUser)?.length > 0) {
      const tempNewUser = { ...newUser };
      setUser(tempNewUser);
    }
  }, [newUser]);

  useEffect(() => {
    const getRoles = async () => {
      const result = await axios.get(`/api/roles`);
      setListOfRoles(result.data);
    };
    getRoles();
  }, []);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClosedialog}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh'
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' ? 'New User' : actionType === 'Edit' ? 'Edit User' : actionType === 'Delete' ? 'Delete User' : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                error={!user.firstName}
                helperText={!user.firstName ? 'First Name can not be empty' : ''}
                label="First Name"
                name="firstName"
                variant="outlined"
                value={user.firstName || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                error={!user.lastName}
                helperText={!user.lastName ? 'Last Name can not be empty' : ''}
                name="lastName"
                variant="outlined"
                value={user.lastName || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                variant="outlined"
                input={<Input />}
                value={user.email || ''}
                error={
                  (user.email?.length > 0 ? !validRegex.test(user?.email?.trim()) : false) ||
                  (error.message?.length > 0 ? true : false) ||
                  !user.email
                }
                helperText={
                  (user.email?.length > 0 ? (!validRegex.test(user?.email?.trim()) ? 'Invalid Email' : '') : '') ||
                  (error?.message?.length > 0 ? error?.message : '') ||
                  (!user.email ? 'Email can not be empty' : '')
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} className="fixSelector">
              <FormControlCustom>
                <InputLabel id="demo-mutiple-name-label">Roles</InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  name="roles"
                  value={user.roles || []}
                  required
                  onChange={handleChange}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {listOfRoles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControlCustom>
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete the this user</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUserSave}
          disabled={actionType !== 'Delete' ? Object.values(user).some((e) => e.length === 0 || e === '') : null}
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClosedialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyUserDialog;
