import MainLayout from 'layout/MainLayout';
import Dashboard from 'views/Dashboard';
import CompanyGuard from 'component/Auth/CompanyGuard';
import AuthGuard from 'component/Auth/AuthGuard';
import Pilots from 'views/Pilots';
import PilotDetails from 'views/Pilots/Pilot Details';
import Aircrafts from 'views/Aircrafts';
import AircraftDetails from 'views/Aircrafts/AircraftDetail';
import Policy from 'views/Policy';
import PolicyDetails from 'views/Policy/PolicyDetails';
import ClaimDetails from 'views/Policy/PolicyClaims/PolicyClaimDetails.js';
import InvoiceDetails from 'views/Policy/PolicyDetails/Invoices/InvoiceDetails';
import Airports from 'views/Airport';
import AirportDetails from 'views/Airport/AirportDetails';
import Insurance from 'views/Insurance';
import Agency from 'views/Agency';
import AgencyDetails from 'views/Agency/AgencyDetails';
import Agents from 'views/Agency/Agents';
import AgentDetails from 'views/Agency/Agents/AgentsDetails';
import CustomerOrg from 'views/CustomerOrg';
import Contacts from 'views/CustomerOrg/Contacts';
import CustomerOrganizationDetails from 'views/CustomerOrg/CustomerOrgDetails';
import ContactsDetails from 'views/CustomerOrg/Contacts/ContactsDetails';
import CompanyUsers from 'views/Admin/Company/Users';
import PolicyOptionAircraft from 'views/PolicyOptionAircraft';
import LienHolder from 'views/LeinHolder';
import AircraftQuote from 'views/AircraftQuote';
import AirportQuote from 'views/AirportQuote';
import UserSettings from 'views/User/Settings';
import GoogleLinkCallBack from 'views/User/GoogleLink';
import AircraftQuoteStepper from 'views/AircraftQuote/QuoteStepper';
import AirportQuoteStepper from 'views/AirportQuote/QuoteStepper';
import RequestView from 'views/AircraftQuote/RequestView';
import QuoteView from 'views/AircraftQuote/QuoteView';
import AircraftRequest from 'views/AircraftRequest';


const PAPRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <CompanyGuard>
        <MainLayout />
      </CompanyGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard/default',
      element: <Dashboard />
    },
    { path: '/company/:companyId/pilots', element: <Pilots /> },
    { path: '/company/:companyId/pilots/:pilotId', element: <PilotDetails /> },
    { path: '/company/:companyId/aircrafts', element: <Aircrafts />},
    { path: '/company/:companyId/aircrafts/:aircraftId', element: <AircraftDetails />},
    { path: '/company/:companyId/airports', element: <Airports />},
    { path: '/company/:companyId/airports/:airportId', element: <AirportDetails />},
    { path: '/company/:companyId/policy', element: <Policy />},
    { path: '/company/:companyId/policy/:policyId', element: <PolicyDetails />},
    { path: '/company/:companyId/policy/:policyId/claim/:claimId', element: <ClaimDetails />},
    { path: '/company/:companyId/policy/:policyId/invoice/:invoiceId', element: <InvoiceDetails />},
    { path: '/company/:companyId/insurance', element: <Insurance /> },
    { path: '/company/:companyId/customer-org', element: <CustomerOrg /> },
    { path: '/company/:companyId/customer-org/:customerOrgId/contacts', element: <Contacts /> },
    { path: '/company/:companyId/customer-org/:customerOrgId/contacts/:contactId', element: <ContactsDetails />},
    { path: '/company/:companyId/customer-org/:customerOrgId', element: <CustomerOrganizationDetails />},
    { path: '/company/:companyId/agency', element: <Agency />},
    { path: '/company/:companyId/agency/:agencyId', element: <AgencyDetails />},
    { path: '/company/:companyId/agency/:agencyId/agents', element: <Agents />},
    { path: '/company/:companyId/agency/:agencyId/agents/:agentId', element: <AgentDetails />},
    { path: '/company/:companyId/users', element: <CompanyUsers /> },
    { path: '/company/:companyId/policyOptionAircraft', element: <PolicyOptionAircraft /> },
    { path: '/company/:companyId/lienholder', element: <LienHolder /> },
    { path: '/company/:companyId/workflow/aircraft-quote', element: <AircraftQuote />},
    { path: '/company/:companyId/workflow/airport-quote', element: <AirportQuote />},
    { path: '/company/:companyId/workflow/aircraft-quote/quote/:quoteId/stepper', element: <AircraftQuoteStepper />},
    { path: '/company/:companyId/workflow/aircraft-quote/quote/:quoteId/single', element: <RequestView />},
    { path: '/company/:companyId/workflow/airport-quote/quote/:quoteId', element: <AirportQuoteStepper />},
    { path: '/company/:companyId/quotes', element: <QuoteView />},
    {path: '/company/:companyId/workflow/aircraft-request', element: <AircraftRequest />},
    { path: '/user/:userId/settings', element: <UserSettings />},
    { path: '/auth/googlelink/callback', element: <GoogleLinkCallBack />}
    
  ]
};

export default PAPRoutes;