import { Page, View, Text, Font } from '@react-pdf/renderer';
import { styles } from '../Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import React from 'react';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const Footer = ({ page, formNumber = '', coninuationPage, endContinuationPage, date, sub, agencyCode = '', agencyAmount='' }) => (
  <>
    <View style={[styles.spaceBetweenRowNoCenter, styles.footerSubContainer, { fontSize: 8 }]}>
      {/* Left column */}
      <View style={styles.leftColumn}>
        {/* Text and boxes */}
        <View style={styles.textRow}>
          <Text style={styles.boldText}>{agencyCode}</Text>
          <View style={{ width: 20 }} />
          <Text style={styles.boldText}>{(+agencyAmount)?.toFixed(2)}</Text>
        </View>

        {/* Footer included in the left column */}
        <View>
          <Text style={styles.boldText}>{formNumber}</Text>
          <Text>Issued: {date}</Text>
          <Text style={styles.boldText}>Page {page} of 2</Text>
          {!sub && (
            <>
              <Text style={{ textAlign: 'center', marginLeft: 100, marginTop: 8, borderTop: '1px solid black' }}>FOR COMPANY USE ONLY</Text>
            </>
          )}
        </View>
      </View>

      {/* Right column */}
      {!sub && (
        <View style={styles.rightColumn}>
          <Text>This is Coverage Identification Continuation Page</Text>
          <Text>
            <Text style={styles.boldText}>{coninuationPage}</Text> of <Text style={styles.boldText}>{endContinuationPage}</Text>
          </Text>
        </View>
      )}
    </View>
  </>
);

const CoverageContinuationPage = ({ policy, formNumber, data, currentPage, endPage, issuedDate }) => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 12, textAlign: 'center' }]}>
        <Text style={styles.boldText}>Coverage Identification Continuation Page</Text>
      </View>
      <View style={styles.content}>
        <View style={styles.centeredText}>
          <Text>This Coverage Indetification Continuation Page is a part of Policy Number {policy?.policyNumber}</Text>
        </View>
        <View style={styles.centeredText}>
          <Text>Issued To: {policy?.clientNameInsured}</Text>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View>
            <Text style={styles.boldText}>ITEM</Text>
          </View>
          <View style={[styles.centeredText, { marginRight: '50px' }]}>
            <Text>Item 5, 6, 7, 10 and 11 of your Coverage Identification Continuation Page are completed or continued as follows:</Text>
          </View>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={[styles.listItemHeading, styles.boldText]}>5 &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                <Text style={styles.boldText}>DESCRIPTION OF AIRCRAFT AND AIRCRAFT PHYSICAL DAMAGE COVERAGE:</Text> You have told us that
                each of the aircraft below (1) is a fixed wing landplane witha FAA Standard Airwothiness Certificate unless noted otherwise
                in the Aircraft Description below; and (2) is solely and unconditionally owned by you unless noted differently in item 1 or
                endorsements we issue. IF NO AGREED VALUE OR DEDUCTIBLE IS SHOWN NO COVERAGE IS PROVIDED.
              </Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.headerRow]}>
                <Text style={[styles.cell, styles.column]}>FAA Registration Number</Text>
                <Text style={[styles.cell, styles.column]}>Aircraft Description</Text>
                <Text style={[styles.cell, styles.column]}>Total Seats</Text>
                <Text style={[styles.cell, styles.column]}>Agreed Value</Text>
                <Text style={[styles.cell, styles.column]}>F. Not in Motion Deductible</Text>
                <Text style={[styles.cell, styles.column, styles.noBorderRight]}>G. In Motion Deductible</Text>
              </View>
              {[...Array(5).keys()].map((i) => (
                <View key={i} style={styles.row}>
                  <Text style={[styles.cell, styles.column]}>{data[i]?.Aircraft?.registrationNo}</Text>
                  <Text style={[styles.cell, styles.column, !data[i]?.Aircraft?.year && styles.hiddenText]}>
                    {data[i]?.Aircraft?.year ? `${data[i]?.Aircraft?.year} ${data[i]?.Aircraft?.make} ` : '1976 Gulfstream AA-5A Cheetah'}
                  </Text>
                  <Text style={[styles.cell, styles.column]}>{data[i]?.Aircraft?.seats}</Text>
                  <Text style={[styles.cell, styles.column]}>{data[i]?.Aircraft?.value}</Text>
                  <Text style={[styles.cell, styles.column]}>{data[i]?.idHullNIMDeductible}</Text>
                  <Text style={[styles.cell, styles.column, styles.noBorderRight]}>{data[i]?.idHullIMDeductible}</Text>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={[styles.boldText, styles.listItemHeading]}>6 &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                <Text style={styles.boldText}>LIABILITY AND MEDICAL PAYMENTS COVERAGE AND LIMITS OF COVERAGE:</Text> Subject to the
                limitations and conditions described in your policy, the most we will pay under each coverage we provided is shown below for
                each aircraft. WHERE NO AMOUNT IS SHOWN, NO COVERAGE IS PROVIDED BY YOUR POLICY.
              </Text>
            </View>
            <View style={{ gap: '-2px' }}>
              <View style={styles.container}>
                <View style={styles.columnSmall}>
                  <Text>FAA Reg. Number</Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text>Limit of Coverage</Text>
                </View>
                <View style={styles.columnLarge}>
                  <Text>D. SINGLE LIMIT BODILY INJURY</Text>
                  <Text>AND PROPERTY DAMAGE</Text>
                </View>
                <View style={styles.columnLarge}>
                  <Text>DL. SINGLE LIMIT BODILY INJURY AND</Text>
                  <Text>PROPERTY DAMAGE</Text>
                </View>
                <View style={styles.columnLarge}>
                  <Text>E. MEDICAL EXPENSE COVERAGE</Text>
                </View>
              </View>
              <View style={[styles.container, { marginTop: '-5px', fontSize: 7 }]}>
                <View style={styles.columnSmall} />
                <View style={styles.columnSmall} />
                <View style={styles.columnLarge}>
                  <Text>__CLUDING PASSENGERS</Text>
                </View>
                <View style={styles.columnLarge}>
                  <Text>LIMITED PASSENGER COVERAGE</Text>
                </View>
                <View style={styles.columnLarge} />
              </View>
              {[...Array(5).keys()].map((i) => (
                <React.Fragment key={i}>
                  <View style={[styles.container, { marginTop: '-5px' }]}>
                    <View
                      style={[
                        styles.columnSmall,
                        !data[i]?.Aircraft?.registrationNo && styles.hiddenText,
                        { borderBottom: '1px solid black', borderRight: '1px black solid' }
                      ]}
                    >
                      <Text>{data[i]?.Aircraft?.registrationNo || 'Hidden'}</Text>
                    </View>
                    <View style={styles.columnSmall}>
                      <Text>Each Person</Text>
                    </View>
                    <View style={[styles.columnLarge, { height: '5px', backgroundColor: 'black', marginTop: '5px' }]} />
                    <View
                      style={[
                        styles.columnLarge,
                        {
                          justifyContent: 'space-between',
                          flexDirection: 'row'
                        }
                      ]}
                    >
                      <Text style={styles.boldText}>$</Text>
                      <Text style={[styles.boldText, !data[i]?.PolicyOptionAircraftLiability?.perPerson && styles.hiddenText]}>
                        {data[i]?.PolicyOptionAircraftLiability?.perPerson || '1000'}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.columnLarge,
                        {
                          justifyContent: 'space-between',
                          flexDirection: 'row'
                        }
                      ]}
                    >
                      <Text style={styles.boldText}>$</Text>
                      <Text style={[styles.boldText, !data[i]?.PolicyOptionAircraftMedical?.perPerson && styles.hiddenText]}>
                        {data[i]?.PolicyOptionAircraftMedical?.perPerson || '1000'}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.container, { marginTop: '-5px' }]}>
                    <View style={styles.columnSmall} />
                    <View style={[styles.columnSmall, {marginTop: '-5px'}]}>
                      <Text>Each Occurence</Text>
                    </View>
                    <View style={[styles.columnLarge, { height: '5px'}]} />
                    <View
                      style={[
                        styles.columnLarge,
                        {
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          marginTop: '-5px'
                        }
                      ]}
                    >
                      <Text style={styles.boldText}>$</Text>
                      <Text style={[styles.boldText, !data[i]?.PolicyOptionAircraftLiability?.perOccurence && styles.hiddenText]}>
                        {data[i]?.PolicyOptionAircraftLiability?.perOccurence || '1000'}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.columnLarge,
                        {
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          marginTop: '-5px'
                        }
                      ]}
                    >
                      <Text style={styles.boldText}>$</Text>
                      <Text style={[styles.boldText, !data[i]?.PolicyOptionAircraftMedical?.perPerson && styles.hiddenText]}>
                        {data[i]?.PolicyOptionAircraftMedical?.perPerson || '1000'}
                      </Text>
                    </View>
                  </View>
                </React.Fragment>
              ))}
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={[styles.boldText, styles.listItemHeading]}>7 &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                <Text style={styles.boldText}>PREMIUMS:</Text> Your cost for each coverage we provide for the designated Aircraft is shown
                below. Where no amount is shown, no coverage is provided.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Footer
        page={1}
        formNumber={formNumber}
        coninuationPage={currentPage}
        endContinuationPage={endPage}
        date={issuedDate}
        sub={false}
        agencyCode={policy?.agencyCode}
        agencyAmount={policy?.agencyAmount}
      />
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.table}>
              <View style={[styles.row, styles.headerRow]}>
                <Text style={[styles.cell, styles.column]}>FAA Registration Number</Text>
                <Text style={[styles.cell, styles.column]}>COV. D</Text>
                <Text style={[styles.cell, styles.column]}>COV. DL</Text>
                <Text style={[styles.cell, styles.column]}>COV. E</Text>
                <Text style={[styles.cell, styles.column]}>COV. F</Text>
                <Text style={[styles.cell, styles.column]}>COV. G</Text>
                <Text style={[styles.cell, styles.column, styles.noBorderRight]}>Aircraft Total</Text>
              </View>
              {[...Array(5).keys()].map((i) => (
                <View key={i} style={styles.row}>
                  {data[i]?.Aircraft?.registrationNo ? (
                    <Text style={[styles.cell, styles.column]}>{data[i]?.Aircraft?.registrationNo}</Text>
                  ) : (
                    <Text style={[styles.hiddenText, styles.column]}>Hidden</Text>
                  )}
                  {data[i]?.idHullAmount ? (
                    <Text style={[styles.cell, styles.column]}>$ {data[i]?.idHullAmount}</Text>
                  ) : (
                    <Text style={[styles.hiddenText, styles.column]}>Hidden</Text>
                  )}
                  {data[i]?.idLiabilityAmount ? (
                    <Text style={[styles.cell, styles.column]}>$ {data[i]?.idLiabilityAmount}</Text>
                  ) : (
                    <Text style={[styles.hiddenText, styles.column]}>Hidden</Text>
                  )}
                  {data[i]?.idMedicalAmount ? (
                    <Text style={[styles.cell, styles.column]}>$ {data[i]?.idMedicalAmount}</Text>
                  ) : (
                    <Text style={[styles.hiddenText, styles.column]}>Hidden</Text>
                  )}
                  <Text style={[styles.cell, styles.column]}>$300</Text>
                  <Text style={[styles.cell, styles.column]}>$400</Text>
                  <Text style={[styles.cell, styles.column, styles.noBorderRight]}>$500</Text>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={[styles.boldText, styles.listItemHeading]}>10 &nbsp; &nbsp; &nbsp;</Text>
            <View
              style={[
                styles.listItemParagraph,
                { marginTop: '-16px', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }
              ]}
            >
              <View style={{ width: '75%' }}>
                <Text>
                  <Text style={styles.boldText}>The Use of the Aircraft:</Text> The specified aircraft will be used for your pleasure and
                  business use and also may be used for the Other Uses described below:
                </Text>
              </View>
              <View style={{ width: '25%', flexDirection: 'row', justifyContent: 'space-between', padding: '0 15px' }}>
                <Text style={styles.boldText}>Page Total</Text>
                <View style={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                  <Text style={styles.boldText}>$ 39,487.00</Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.headerRow]}>
                <Text style={[styles.cell, styles.firstColumn]}>FAA Registration Number</Text>
                <Text style={[styles.noCenterCell, styles.secondColumn]}>Other Uses Approved</Text>
              </View>
              {[...Array(5).keys()].map((i) => (
                <View key={i} style={styles.noCenterRow}>
                  <Text style={[styles.noCenterCell, styles.firstColumn, !data[i]?.Aircraft?.registrationNo && styles.hiddenText]}>
                    {data[i]?.Aircraft?.registrationNo}
                  </Text>
                  {data[i]?.idUse ? (
                    <Text styles={[styles.noCenterCell, styles.secondColumn, styles.noBorderRight]}>
                      {data[i]?.PolicyOptionAircraftUse?.description}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        styles.noCenterCell,
                        styles.secondColumn,
                        styles.noBorderRight,
                        !data[i]?.PolicyOptionAircraftUse?.description && styles.hiddenText
                      ]}
                    >
                      No ENDORSEMENTS
                    </Text>
                  )}
                </View>
              ))}
            </View>
          </View>
          <View style={styles.listItem}>
            <Text style={[styles.boldText, styles.listItemHeading]}>11 &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>
                <Text style={styles.boldText}>Additional Interests:</Text> Payment for Aircraft Physical Damage or Loss under Coverage F or
                G will be made to you and the specified lender below:
              </Text>
            </View>
            <View style={{ gap: '-2px' }}>
              <View style={styles.container}>
                <View style={styles.columnSmall}>
                  <Text>FAA Reg. Number</Text>
                </View>
                <View style={styles.columnLarge}>
                  <Text>Lienholder information</Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text>Lienholder Int. Coverage</Text>
                </View>
                <View style={styles.columnSmall}>
                  <Text>Balance</Text>
                </View>
              </View>
              {[...Array(5).keys()].map((i) => (
                <React.Fragment key={i}>
                  <View style={[styles.container]}>
                    <View
                      style={[
                        styles.columnSmall,
                        !data[i]?.faa && styles.hiddenText,
                        { borderBottom: '1px solid black', borderRight: '1px black solid' }
                      ]}
                    >
                      <Text>{data[i]?.faa || 'Hidden'}</Text>
                    </View>
                    <View
                      style={[
                        styles.columnLargeNoCenter,
                        !data[i]?.desc && styles.hiddenText,
                        { borderBottom: '1px solid black', borderRight: '1px black solid' }
                      ]}
                    >
                      <Text>{data[i]?.desc || '123 Address St.'}</Text>
                    </View>
                    <View
                      style={[
                        styles.columnSmall,
                        !data[i]?.desc && styles.hiddenText,
                        { borderBottom: '1px solid black', borderRight: '1px black solid' }
                      ]}
                    >
                      <Text>{data[i]?.desc || 'Yes'}</Text>
                    </View>
                    <View
                      style={[
                        styles.columnSmall,
                        !data[i]?.desc && styles.hiddenText,
                        { borderBottom: '1px solid black', borderRight: '1px black solid' }
                      ]}
                    >
                      <Text>{data[i]?.desc || 'No'}</Text>
                    </View>
                  </View>
                </React.Fragment>
              ))}
            </View>
          </View>
        </View>
      </View>
      <Footer page={2} formNumber={formNumber} date={issuedDate} sub={true} agencyCode={policy?.agencyCode} />
    </Page>
  </>
);

export default CoverageContinuationPage;
