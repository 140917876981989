// react Imports
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Mui Imports
import {
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Divider,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  InputAdornment,
} from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Local Imports
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import { Transition } from 'utils/transition';

import InvoicePDF from './InvoicePDF';
import { formatDate } from 'utils/FormatUtils';

const columns = [
  {
    id: 'dateReceieved',
    label: 'Date Receieved'
  },
  {
    id: 'amount',
    label: 'Amount ($)'
  },
  {
    id: 'description',
    label: 'Description'
  }
];

const InvoiceDetails = () => {
  // Initialize Axios
  const auth = useAuth();
  const axios = initializeAxios(auth);

  // Params
  const { companyId, invoiceId } = useParams();

  // Reactive States
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState({});
  const [paymentId, setPaymentId] = useState(null);
  const [totalPaid, setTotalPaid] = useState(0);
  const [actionType, setActionType] = useState('');
  const [idImmutable, setIdImmutable] = useState(null);
  const [tempTotalPaid, setTempTotalPaid] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setActionType('Create');
    setTempTotalPaid(totalPaid);
    setOpen(true);
  };

  const handleClosedialog = () => {
    setPayment({});
    setOpen(false);
    setTempTotalPaid(0);
  };

  const handleEdit = (editPayment) => {
    setPayment({ ...editPayment });
    setPaymentId(editPayment.id);
    setActionType('Edit');
    const oldAmount = +editPayment?.amount;
    setTempTotalPaid(+totalPaid - oldAmount);
    setOpen(true);
  };

  const handleDelete = (deletePayment) => {
    setPaymentId(deletePayment.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayment({
      ...payment,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setPayment({
      ...payment,
      dateRecieved: date !== '' ? date : null
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/invoices/${invoiceId}/companyId/${companyId}/payment`, {
          payment: {
            amount: payment?.amount,
            description: payment?.description,
            dateReceived: payment?.dateRecieved
          }
        });
        setPayments([...payments, response.data]);
        const newAmount = +response.data.amount;
        const newTotalPaid = (+tempTotalPaid + newAmount).toFixed(2);
        setTotalPaid(newTotalPaid);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/invoices/${invoiceId}/companyId/${companyId}/payment/${paymentId}`, {
          payment: {
            amount: payment?.amount,
            description: payment?.description,
            dateReceived: payment?.dateRecieved
          }
        });
        const tempArray = [...payments];
        const index = tempArray.findIndex((e) => e.id === paymentId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setPayments(tempArray);
        const newAmount = +response.data?.amount;
        setTotalPaid((+tempTotalPaid + newAmount).toFixed(2));
        break;
      }
      case 'Delete': {
        await axios.delete(`/api/invoices/${invoiceId}/companyId/${companyId}/payment/${paymentId}`);
        const tempArray = [...payments];
        const index = tempArray.findIndex((e) => e.id === paymentId);
        const newAmount = +tempArray[index]?.amount;
        setTotalPaid(+totalPaid - newAmount < 0 ? 0 : (+totalPaid - newAmount).toFixed(2));
        tempArray.splice(index, 1);
        setPayments(tempArray);
      }
    }
    setIsLoading(false);
    handleClosedialog();
  };

  useEffect(() => {
    const getInvoice = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/invoices/${invoiceId}/companyId/${companyId}`);
      setInvoice(response.data);
      setPayments([...response.data.InvoicePayment]);
      setIdImmutable(response.data.Policy.idImmutable);
      setTotalPaid(response.data.InvoicePayment.reduce((total, next) => total + (next?.amount ? +next.amount : 0), 0.0).toFixed(2));
      setIsLoading(false);
    };
    getInvoice();
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Invoice Details" divider={false}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/policy/`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Policies
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/policy/${idImmutable}`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Policy Details
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Invoice Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Invoice Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Policy Number"
                            variant="outlined"
                            value={invoice?.Policy?.policyNumber || ''}
                            readOnly
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Invoice Date"
                            variant="outlined"
                            value={new Date(invoice?.invoiceDate).toLocaleDateString() || ''}
                            readOnly
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Invoice End Date"
                            variant="outlined"
                            value={new Date(invoice?.endDate).toLocaleDateString() || ''}
                            readOnly
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Bill Date"
                            variant="outlined"
                            value={new Date(invoice?.billDate).toLocaleDateString() || ''}
                            readOnly
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Grid item>
                    <Typography component="span" variant="h1">
                      {(+totalPaid).toFixed(2) === (+invoice?.amount).toFixed(2) ? 'Paid' : 'Not Paid'}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="subtitle2" sx={{ mr: 2 }}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Amount Paid"
                        variant="outlined"
                        value={totalPaid || '0.00'}
                        readOnly
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      />
                    </Typography>
                    <Typography variant="subtitle2">
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Amount Due"
                        variant="outlined"
                        value={(+invoice?.amount)?.toFixed(2) || '0.00'}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        readOnly
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {!isLoading && (
                    <Button variant="contained" color="primary">
                      <PDFDownloadLink
                        document={<InvoicePDF invoice={invoice} />}
                        fileName={`invoice-${invoiceId}-${new Date().toLocaleDateString()}`}
                        className="pdf-link"
                      >
                        {(blob, url, loading) => (loading ? 'Loading PDF....' : 'Download Invoice PDF')}
                      </PDFDownloadLink>
                    </Button>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <CustomTable
                  columns={columns}
                  data={payments}
                  isLoading={isLoading}
                  title="Invoice Payments"
                  hasCreateButton={true}
                  handleOpenCreate={handleOpenDialog}
                >
                  {(row) => (
                    <>
                      <Tooltip title="Edit Payment" placement="top">
                        <span>
                          <IconButton color="primary" aria-label="Edit" size="large" onClick={() => handleEdit(row)}>
                            <EditTwoToneIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete Payment">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleDelete(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosedialog}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              maxHeight: '100%',
              height: '100vh'
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create'
            ? 'New Payment'
            : actionType === 'Edit'
            ? 'Edit Payment'
            : actionType === 'Delete'
            ? 'Delete Payment'
            : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Amount"
                  name="amount"
                  value={payment?.amount || ''}
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  error={payment?.amount < 0 || +payment?.amount + +tempTotalPaid > +invoice.amount || !payment?.amount}
                  helperText={
                    !payment?.amount
                      ? 'Payment cannot be Empty'
                      : payment?.amount < 0
                      ? 'Cannot be less than Zero'
                      : +payment?.amount + +tempTotalPaid > +invoice?.amount
                      ? 'Payment Cannot exceed Invoice bill amount'
                      : ''
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  inputFormat="MM/dd/yyyy"
                  margin="normal"
                  renderInput={(props) => (
                    <TextField
                      error={!payment?.dateRecieved}
                      helperText={!payment?.dateRecieved ? 'Cannot be Empty' : ''}
                      sx={{
                        pb: 2,
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: !payment?.dateRecieved ? 'red' : 'black'
                        }
                      }}
                      fullWidth
                      {...props}
                    />
                  )}
                  value={formatDate(payment?.dateRecieved) || null}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  keyboardIcon={<EventTwoToneIcon />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Description"
                  name="description"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={payment?.description || ''}
                  error={!payment?.description}
                  helperText={!payment?.description ? 'Cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          ) : (
            <DialogContentText>Confirm if you want to Delete this Payment</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              actionType !== 'Delete'
                ? !payment?.amount ||
                  !payment?.description ||
                  payment?.amount < 0 ||
                  (+payment?.amount + +tempTotalPaid).toFixed(2) > (+invoice.amount)?.toFixed(2) ||
                  !payment?.dateRecieved
                : false
            }
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClosedialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceDetails;
