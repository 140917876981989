import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, CardContent, TextField, MenuItem, Divider, Button, Tooltip, IconButton } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import AircraftDialog from 'component/AircraftDialog';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import { states } from 'utils/states';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PilotDialog from 'component/PilotDialog';

const aircraftColumns = [
  {
    id: 'registrationNo',
    label: 'Registration Number'
  },
  {
    id: 'registeredOwner',
    label: 'Registered Owner'
  }
];

const pilotColumns = [
  {
    id: 'firstname',
    label: 'First Name'
  },
  {
    id: 'lastname',
    label: 'Last Name'
  }
];

const CustomerOrganizationDetails = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);
  const { companyId, customerOrgId } = useParams();
  const [openSelectedDialog, setOpenSelectedDialog] = useState('');
  const [actionType, setActionType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customerOrg, setCustomerOrg] = useState({});
  const [listOfTypes, setListOfTypes] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);
  const [pilots, setPilots] = useState([]);

  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCustomerOrg({
      ...customerOrg,
      [name]: value
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/customer-org/${customerOrgId}/companyId/${companyId}`, {
      customer: {
        name: customerOrg?.name,
        orgType: customerOrg?.orgType,
        address1: customerOrg?.address1,
        address2: customerOrg?.address2,
        city: customerOrg?.city,
        state: customerOrg?.state,
        zip: customerOrg?.zip,
        phone: customerOrg?.phone,
        email: customerOrg?.email,
        title: customerOrg?.title
      }
    });
    setCustomerOrg({ ...response.data });
    setIsLoading(false);
  };

  const handleDialogSave = async (dataDialog) => {
    setIsLoading(true);
    if (dataDialog.aircraft) {
      const aircraft = { ...dataDialog.aircraft, idCustomerOrganization: +customerOrgId };
      const response = await axios.post(`/api/aircraft/companyId/${companyId}`, {
        aircraft,
        detailsFlag: false
      });
      setAircrafts([...aircrafts, response.data]);
    }
    if (dataDialog.pilot) {
      const pilot = {
        ...dataDialog,
        pilot: {
          ...dataDialog.pilot,
          idCustomerOrganization: +customerOrgId
        }
      };
      const response = await axios.post(`/api/pilots/companyId/${companyId}`, {
        ...pilot,
        detailsFlag: false
      });
      setPilots([...pilots, response.data]);
    }

    setIsLoading(false);
    setOpenSelectedDialog('');
  };

  const handleGoToDetail = (item, type) => {
    navigate(`/company/${companyId}/${type}/${item.id}`);
  };

  const goToContacts = () => {
    navigate(`/company/${companyId}/customer-org/${customerOrgId}/contacts`);
  };

  const handleClickOpendialog = (dialogName) => {
    setOpenSelectedDialog(dialogName);
    setActionType('CreateWorkflow');
  };
  const handleClosedialog = () => {
    setOpenSelectedDialog('');
  };

  useEffect(() => {
    const getPolicies = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/policy/companyId/${companyId}/customerOrgId/${customerOrgId}/pagination`, {
        params: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage
        }
      });
      setPagination({ ...pagination, count: response.data.count });
      setIsLoading(false);
    };

    getPolicies();
  }, [companyId, pagination.rowsPerPage, pagination.page]);

  useEffect(() => {
    const getCustomerOrg = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/customer-org/${customerOrgId}/companyId/${companyId}`);
      setCustomerOrg({ ...response.data });
      setIsLoading(false);
    };

    const getAircrafts = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/aircraft/companyId/${companyId}/customerOrgId/${customerOrgId}`);
      setAircrafts(response.data);
      setIsLoading(false);
    };

    const getPilots = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/pilots/companyId/${companyId}/customerOrgId/${customerOrgId}`);
      setPilots(response.data);
      setIsLoading(false);
    };
    const getOrgTypes = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=OrgType`);
      setListOfTypes([...response.data]);
    };

    getCustomerOrg();
    getOrgTypes();
    getAircrafts();
    getPilots();
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Named Insured Details" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/customer-org/`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Named Insured
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Named Insured Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="name"
                            label="Name"
                            variant="outlined"
                            value={customerOrg?.name || ''}
                            error={!customerOrg?.name}
                            helperText={!customerOrg?.name ? 'Cannot be Empty' : ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Organization Type"
                            variant="outlined"
                            name="orgType"
                            value={customerOrg?.Options?.id || ''}
                            select
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                            }}
                            onChange={handleChange}
                          >
                            {listOfTypes?.map((type) => (
                              <MenuItem key={type.id} value={type.id}>
                                {type.val}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="email"
                            label="Email"
                            variant="outlined"
                            value={customerOrg?.email || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="phone"
                            label="Phone Number"
                            variant="outlined"
                            value={customerOrg?.phone || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="title"
                            label="Title"
                            variant="outlined"
                            value={customerOrg?.title || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Organizaition Address
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Address 1"
                          variant="outlined"
                          name="address1"
                          value={customerOrg?.address1 || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Address 2"
                          name="address2"
                          variant="outlined"
                          value={customerOrg?.address2 || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          name="city"
                          label="City"
                          variant="outlined"
                          value={customerOrg?.city || ''}
                          error={!customerOrg?.city}
                          helperText={!customerOrg?.city ? 'Cannot be Empty' : ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          select
                          name="state"
                          label="State"
                          variant="outlined"
                          value={customerOrg?.state || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        >
                          {states.map((state) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          name="zip"
                          label="Zipcode"
                          variant="outlined"
                          value={customerOrg?.zip || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                      <Grid item sm={6} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          disabled={
                            !customerOrg?.name ||
                            !customerOrg?.orgType ||
                            !customerOrg?.city ||
                            !auth.writePrivledge(auth.user, 'CustomerOrg_Adm')
                          }
                        >
                          Save Changes
                        </Button>
                      </Grid>
                      <Grid item sm={6} md={4}>
                        <Button variant="contained" color="primary" onClick={goToContacts}>
                          Go to Contacts
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Divider />
              <CardContent>
                <AircraftDialog
                  open={openSelectedDialog == 'aircraft' ? true : false}
                  handleAircraftSave={handleDialogSave}
                  handleClosedialog={handleClosedialog}
                  aircraft={aircrafts}
                  actionType={actionType}
                  selectedCustomerOrg={customerOrg}
                  selectedContact={'selectedCustomerContact'}
                />
                <CustomTable
                  columns={aircraftColumns}
                  data={aircrafts}
                  isLoading={isLoading}
                  isSearch={true}
                  filterLabel={true}
                  hasCreateButton={true}
                  handleOpenCreate={() => handleClickOpendialog('aircraft')}
                  title="Aircrafts"
                >
                  {(row) => (
                    <>
                      <Tooltip title="Aircraft Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row, 'aircrafts')}>
                          <TextSnippetIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
              <Divider />
              <CardContent>
                <PilotDialog
                  open={openSelectedDialog == 'pilot' ? true : false}
                  handlePilotSave={handleDialogSave}
                  handleClosedialog={handleClosedialog}
                  pilots={pilots}
                  actionType={actionType}
                  selectedCustomerOrg={customerOrg}
                  selectedContact={'selectedCustomerContact'}
                />
                <CustomTable
                  columns={pilotColumns}
                  data={pilots}
                  isLoading={isLoading}
                  handleOpenCreate={() => handleClickOpendialog('pilot')}
                  title="Pilots"
                  hasCreateButton={true}
                  isSearch={true}
                  filterLabel={true}
                >
                  {(row) => (
                    <>
                      <Tooltip title="Aircraft Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row, 'pilots')}>
                          <TextSnippetIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerOrganizationDetails;
