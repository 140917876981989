import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Divider,
  Autocomplete,
  Button,
  Tooltip,
  IconButton,
  Box
} from '@mui/material';
import LoadingOverlay from 'component/LoadingOverlay';
import { useEffect, useState, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router';
import { gridSpacing } from 'config.js';
import { Link } from 'react-router-dom';
import Breadcrumb from 'component/Breadcrumb';

import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/EditTwoTone';

import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

// Dialogs
import CustomerOrgDialog from 'component/CustomerOrgDialog';
import AgencyDialog from 'component/AgencyDialog';
import AgentDialog from 'component/AgentDialog';
import AircraftQuoteComponent from '../AircraftQuoteComponent';
import AircraftQuotePilots from '../Pilot';
import AircraftQuoteLienHolder from '../OtherInterests';
import AircraftQuotePilotHours from '../PilotHours';
import AircraftDialog from 'component/AircraftDialog';

const RequestView = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const navigate = useNavigate();
  const { companyId, quoteId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState('');
  const [selectedObj, setSelectedObj] = useState({});
  const [data, setData] = useState({
    customerOrganizations: [],
    agencies: [],
    agents: [],
    insurances: [],
    aircrafts: [],
    otherInterests: [],
    pilots: [],
    pilotHours: []
  });
  const [aircraftRelations, setAircraftRelations] = useState([]);
  const [showAircraft, setShowAircraft] = useState(true);
  const [showRelations, setShowRelations] = useState(true);
  const [state, setState] = useState({});
  const [dataId, setDataId] = useState({
    idAgency: null,
    idCustomerOrganization: null
  });
  const initialState = {
    openAircraft: false,
    openPilot: false,
    openCustomerOrg: false,
    openAgency: false,
    openAgent: false
  };

  const selectedState = {
    selectedCustomerOrg: {},
    selectedAgency: {},
    selectedAgent: {},
    selectedAircraft: [],
    activeAircraft: {},
    activePilot: {},
    selectedPilots: [],
    selectedInterests: [],
    selectedInsurance: [],
    activeQuoteId: null
  };

  function reducer(state, action) {
    if (action.type === 'updateSelection') {
      return { ...state, ...action.payload }; // Merges multiple updates
    }
    return { ...state, [action.type]: action.payload };
  }

  const [open, dispatch] = useReducer(reducer, initialState);
  const [selected, dispatchSelected] = useReducer(reducer, selectedState);
  const method = actionType === 'Edit' ? 'put' : 'post';
  const switchStatement = {
    idCustomerOrganization: (value) => {
      const customerOrg = data?.customerOrganizations.find((item) => item.id === value);
      setDataId({
        ...dataId,
        idCustomerOrganization: value
      });
      dispatchSelected({
        type: 'updateSelection',
        payload: {
          selectedCustomerOrg: { ...customerOrg },
          selectedCustomerContact: {}
        }
      });
      handleSelectSave(value, 'idCustomerOrganization');
    },
    idAgency: (value) => {
      const agency = data?.agencies.find((item) => item.id === value);
      setDataId((prevData) => ({
        ...prevData,
        idAgency: value
      }));
      dispatchSelected({
        type: 'updateSelection',
        payload: {
          selectedAgency: { ...agency },
          selectedAgent: {}
        }
      });
      handleSelectSave(value, 'idAgency');
    },
    idAgent: (value) => {
      const agent = data?.agents.find((item) => item.id === value);
      dispatchSelected({ type: 'selectedAgent', payload: { ...agent } });
      handleSelectSave(value, 'idAgent');
    },
    idInsurance: (value) => {
      const insurance = data?.insurances.find((item) => item.id === value);
      dispatchSelected({ type: 'selectedInsurance', payload: { ...insurance } });
      handleSelectSave(value, 'idInsurance');
    },
    customerOrganizationOpen: () => {
      dispatch({ type: 'openCustomerOrg', payload: true });
    },
    customerOrganizationClose: () => {
      dispatch({ type: 'openCustomerOrg', payload: false });
    },
    customerOrganization: async (value) => {
      const url =
        actionType === 'Edit'
          ? `/api/customer-org/${state?.idCustomerOrganization}/companyId/${companyId}`
          : `/api/customer-org/companyId/${companyId}`;

      const response = await axios[method](url, {
        customer: {
          ...value,
          idCompany: +companyId
        }
      });
      return {
        type: 'idCustomerOrganization',
        value: response.data.id,
        array: 'customerOrganizations',
        data: response.data
      };
    },
    agencyOpen: () => {
      dispatch({ type: 'openAgency', payload: true });
    },
    agencyClose: () => {
      dispatch({ type: 'openAgency', payload: false });
    },
    agency: async (value) => {
      const url = actionType === 'Edit' ? `/api/agency/${state?.idAgency}/companyId/${companyId}` : `/api/agency/companyId/${companyId}`;
      const response = await axios[method](`${url}`, {
        agency: {
          ...value,
          idCompany: +companyId
        }
      });
      return {
        type: 'idAgency',
        value: response.data.id,
        array: 'agencies',
        data: response.data
      };
    },
    agentOpen: () => {
      dispatch({ type: 'openAgent', payload: true });
    },
    agentClose: () => {
      dispatch({ type: 'openAgent', payload: false });
    },
    agent: async (value) => {
      const url = actionType === 'Edit' ? `/api/agent/${state?.idAgent}/companyId/${companyId}` : `/api/agent/companyId/${companyId}`;
      const displayName = `${value?.firstname} ${value?.lastname}`;
      const response = await axios[method](`${url}`, {
        agent: {
          ...value,
          displayName,
          idAgency: state?.idAgency
        }
      });
      return {
        type: 'idAgent',
        value: response.data.id,
        array: 'agents',
        data: response.data
      };
    },
    aircraft: async (value) => {
      setIsLoading(true);
      handleCreateAircraftRelation(value);
      handleCreateRelations();
      setShowAircraft(true);
      dispatch({ type: 'openAircraft', payload: false });
    },
    aircraftOpen: () => {
      dispatch({ type: 'openAircraft', payload: true });
    },
    aircraftClose: () => {
      dispatch({ type: 'openAircraft', payload: false });
    },
    default: () => console.warn('Unhandled key in switchStatement')
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switchStatement[name](value) || switchStatement.default;
    if (name === 'idAgency') {
      setState({
        ...state,
        [name]: value,
        idAgent: null
      });
    } else {
      setState({
        ...state,
        [name]: value
      });
    }
  };

  const handleCreate = (type) => {
    setActionType('Create');
    switchStatement[type]() || switchStatement.default;
  };

  const handleEdit = (type, value) => {
    setActionType('Edit');
    setSelectedObj({ ...value });
    switchStatement[type]() || switchStatement.default;
  };

  const handleClose = (type) => {
    switchStatement[type]() || switchStatement.default;
  };

  const handleAircraftSelect = (_, row) => {
    if (row) {
      if (selected.selectedAircraft.findIndex((item) => item.id === row.id) !== -1) {
        //Delete
        const tempAircrafts = [...selected.selectedAircraft];
        const tempRelations = [...aircraftRelations];
        const index = tempAircrafts.findIndex((item) => item.id === row.id);
        tempAircrafts.splice(index, 1);
        dispatchSelected({ type: 'selectedAircraft', payload: tempAircrafts });
        if (tempRelations.findIndex((item) => item.aircraftId === row.id) !== -1) {
          const relationIndex = tempRelations.findIndex((item) => item.aircraftId === row.id);
          tempRelations.splice(relationIndex, 1);
          setAircraftRelations(tempRelations);
          handleDeleteAircraftRelation(
            row,
            aircraftRelations[relationIndex].pilotRelations,
            aircraftRelations[relationIndex].otherInterests
          );
        }
      } else {
        //Create
        dispatchSelected({
          type: 'updateSelection',
          payload: {
            activeAircraft: row,
            selectedAircraft: [...selected.selectedAircraft, { ...row }],
            selectedPilots: [],
            selectedInterests: []
          }
        });
        setShowAircraft(false);
      }
    }
  };

  const handlePilotSelect = (_, row) => {
    console.log('selectedPilots', selected.selectedPilots);
    console.log('row', row);
    // Delete
    if (selected.selectedPilots.findIndex((item) => item.id === row.id) !== -1) {
      const tempPilots = [...selected.selectedPilots];
      const index = tempPilots.findIndex((item) => item.id === row.id);
      tempPilots.splice(index, 1);
      dispatchSelected({ type: 'selectedPilots', payload: tempPilots });
    } else {
      //Create
      dispatchSelected({
        type: 'updateSelection',
        payload: {
          activePilot: row,
          selectedPilots: [...selected.selectedPilots, { ...row }]
        }
      });
      setShowRelations(false);
    }
  };

  const handleInterestSelect = (_, row) => {
    // Delete
    if (selected.selectedInterests.findIndex((item) => item.id === row.id) !== -1) {
      const tempInterests = [...selected.selectedInterests];
      const index = tempInterests.findIndex((item) => item.id === row.id);
      tempInterests.splice(index, 1);
      dispatchSelected({ type: 'selectedInterests', payload: tempInterests });
    } else {
      //Create
      dispatchSelected({ type: 'selectedInterests', payload: [...selected.selectedInterests, { ...row }] });
    }
  };

  const handleSaveRelations = () => {
    setActionType('Details');
    switchStatement['aircraftOpen']() || switchStatement.default;
    setAircraftRelations([
      ...aircraftRelations,
      {
        aircraftId: selected.activeAircraft.id,
        pilotRelations: selected.selectedPilots || [],
        otherInterests: selected.selectedInterests || []
      }
    ]);
  };

  const handleCreateAircraftRelation = async (aircraftDialog) => {
    const tempAircraft = { ...aircraftDialog.aircraft, idAircraft: selected.activeAircraft.id };
    await axios.post(`/api/policy/${quoteId}/aircraft/companyId/${companyId}`, {
      aircraft: {
        ...tempAircraft
      }
    });
  };

  const handleDeleteAircraftRelation = async (aircraft, pilots, interests) => {
    await axios.delete(`/api/aircraft/${aircraft.id}/policyId/${quoteId}/relations/companyId/${companyId}`, {
      pilots,
      interests
    });
  };

  const handleCreateRelations = () => {
    aircraftRelations.forEach(async (relation) => {
      if (relation.otherInterests?.length > 0) {
        await axios.post(`/api/aircraft/${selected.activeAircraft.id}/lienholder/companyId/${companyId}/multiple`, {
          data: relation.otherInterests.map((item) => ({ idAircraft: relation.aircraftId, idLienHolder: item.id }))
        });
      }
      if (relation.pilotRelations?.length > 0) {
        await axios.post(`/api/aircraft/${selected.activeAircraft.id}/pilots/companyId/${companyId}/multiple`, {
          data: relation.pilotRelations.map((item) => ({ idAircraft: relation.aircraftId, idPilot: item.id }))
        });
      }
    });
  };

  const handleSave = async (type, value) => {
    setIsLoading(true);
    const action = switchStatement[type] || switchStatement.default;
    const result = action ? await action(value) : null;
    if (result && result.type) {
      if (actionType === 'Create') {
        setState({
          ...state,
          [result.type]: result.value
        });
        setData((prevData) => ({
          ...prevData,
          [result.array]: [...(prevData[result.array] || []), result.data]
        }));
      } else {
        const tempArray = [...data[result.array]];
        const index = tempArray.findIndex((item) => item.id === state[result.type]);
        tempArray[index] = result.data;
        setData((prevData) => ({
          ...prevData,
          [result.array]: tempArray
        }));
      }
    }
    setIsLoading(false);
  };

  const handlePolicySave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/policy/${quoteId}/companyId/${companyId}`, {
      policy: {
        idPolicyStatus: 42,
        clientNameInsured: selected.selectedCustomerOrg.name
      }
    });
    navigate(`/company/${companyId}/policy/${response.data.id}`);
    setIsLoading(false);
  };

  const handleSelectSave = async (value, item) => {
    await axios.put(`/api/policy/${quoteId}/companyId/${companyId}`, {
      policy: {
        [item]: value
      }
    });
  };

  useEffect(() => {
    const getInsurances = async () => {
      const result = await axios.get(`/api/insurance/companyId/${companyId}`);
      setData((prevData) => ({ ...prevData, insurances: Array.isArray(result.data) ? [...result.data] : [] }));
    };

    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setData((prevData) => ({ ...prevData, customerOrganizations: Array.isArray(result.data) ? [...result.data] : [] }));
    };

    const getAgencies = async () => {
      const result = await axios.get(`/api/agency/companyId/${companyId}`);
      setData((prevData) => ({ ...prevData, agencies: Array.isArray(result.data) ? [...result.data] : [] }));
    };

    const getAgents = async () => {
      const result = await axios.get(`/api/agent/companyId/${companyId}`);
      setData((prevData) => ({ ...prevData, agents: Array.isArray(result.data) ? [...result.data] : [] }));
    };
    const getQuote = async () => {
      const response = await axios.get(`/api/policy/${quoteId}/companyId/${companyId}`);
      if (response.data?.length > 0) {
        const { Agency, Agent, CustomerOrganization, PolicyAircraft, insurance, ...rest } = response.data[0];
        if (Agency) {
          setState((prevData) => ({ ...prevData, idAgency: rest.idAgency }));
          dispatchSelected({ type: 'selectedAgency', payload: { ...Agency } });
          setDataId((prevData) => ({ ...prevData, idAgency: rest.idAgency }));
        }
        if (Agent) {
          dispatchSelected({ type: 'selectedAgent', payload: { ...Agent } });
          setState((prevData) => ({ ...prevData, idAgent: rest.idAgent }));
        }
        if (insurance) {
          dispatchSelected({ type: 'selectedInsurance', payload: { ...insurance } });
          setState((prevData) => ({ ...prevData, idInsurance: rest.idInsurance}));
        }
        if (CustomerOrganization) {
          dispatchSelected({ type: 'selectedCustomerOrg', payload: { ...CustomerOrganization } });
          setState((prevData) => ({ ...prevData, idCustomerOrganization: rest.idCustomerOrganization }));
          setDataId((prevData) => ({ ...prevData, idCustomerOrganization: rest.idCustomerOrganization }));
        }
        if (PolicyAircraft?.length > 0) {
          dispatchSelected({ type: 'selectedAircraft', payload: PolicyAircraft?.map((item) => item.Aircraft) });
          setAircraftRelations(
            PolicyAircraft.map((item) => ({
              aircraftId: item.idAircraft,
              otherInterests: item.Aircraft?.AircraftLienHolder?.length > 0 ? item?.Aircraft?.AircraftLienHolder?.map((other) => other?.LienHolder) : [],
              pilotRelations: item.Aircraft?.PilotAircraft?.length > 0 ? item?.Aircraft?.PilotAircraft?.map((other) => other?.Pilot) : []
            }))
          );
        }
      }
    };
    setIsLoading(true);
    getAgencies();
    getAgents();
    getCustomerOrgs();
    getInsurances();
    getQuote();
    setIsLoading(false);
  }, [quoteId, companyId]);
  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <CustomerOrgDialog
        companyId={companyId}
        open={open.openCustomerOrg}
        handleClosedialog={() => handleClose('customerOrganizationClose')}
        actionType={actionType}
        handleCustomerSave={(value) => handleSave('customerOrganization', value)}
        customer={selectedObj}
      />
      <AgencyDialog
        actionType={actionType}
        open={open.openAgency}
        handleClosedialog={() => handleClose('agencyClose')}
        handleAgencySave={(value) => handleSave('agency', value)}
        agency={selectedObj}
      />
      <AgentDialog
        open={open.openAgent}
        agent={selectedObj}
        handleClosedialog={() => handleClose('agentClose')}
        handleAgentSave={(value) => handleSave('agent', value)}
        actionType={actionType}
      />
      <AircraftDialog
        isPolicyAircraft={true}
        actionType="Details"
        aircraft={{ idAircraft: selected.activeAircraft.id, actionType: 'Edit' }}
        open={open.openAircraft}
        handleClosedialog={() => handleClose('aircraftClose')}
        handleAircraftSave={(value) => handleSave('aircraft', value)}
      />
      <Breadcrumb sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography
          component={Link}
          to={`/company/${companyId}/workflow/aircraft-quote/`}
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Aircraft Quote Request
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Quote workflow
        </Typography>
      </Breadcrumb>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          Aircraft Quote Request
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography component="div" variant="h5">
                Client Named Insured
              </Typography>
              <Grid sx={{ justifyContent: 'space-between' }}>
                <Tooltip title="Edit Client Named Insured" placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="Create"
                      size="large"
                      sx={{ mr: 'auto', display: 'inline-flex' }}
                      disabled={!state?.idCustomerOrganization}
                      onClick={() =>
                        handleEdit(
                          'customerOrganizationOpen',
                          data.customerOrganizations.find((item) => item.id === state.idCustomerOrganization)
                        )
                      }
                    >
                      <Edit fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Add Client Named Insured" placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="Create"
                      size="large"
                      sx={{ mr: 'auto', display: 'inline-flex' }}
                      onClick={() => handleCreate('customerOrganizationOpen')}
                    >
                      <Add fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    <Autocomplete
                      sx={{ mt: 1 }}
                      fullWidth
                      options={data.customerOrganizations?.length > 0 ? data.customerOrganizations : []}
                      getOptionLabel={(option) => ` ${option.id}: ${option.name}`}
                      value={data.customerOrganizations?.find((pil) => pil.id === state.idCustomerOrganization) || null}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(_, newValue) => {
                        const selectedCustomerOrgId = newValue === undefined ? null : newValue?.id;
                        handleChange({
                          target: { name: 'idCustomerOrganization', value: selectedCustomerOrgId }
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={!state?.idCustomerOrganization}
                          helperText={!state?.idCustomerOrganization ? 'Must not be Empty' : ''}
                          {...params}
                          label="Customer Organization*"
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography component="div" variant="h5">
                Agency
              </Typography>
              <Grid sx={{ justifyContent: 'space-between' }}>
                <Tooltip title="Edit Agency" placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="Create"
                      size="large"
                      sx={{ mr: 'auto', display: 'inline-flex' }}
                      disabled={!state.idAgency}
                      onClick={() =>
                        handleEdit(
                          'agencyOpen',
                          data.agencies.find((item) => item.id === state.idAgency)
                        )
                      }
                    >
                      <Edit fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Add Agency" placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="Create"
                      size="large"
                      sx={{ mr: 'auto', display: 'inline-flex' }}
                      onClick={() => handleCreate('agencyOpen')}
                    >
                      <Add fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    <TextField value={state?.idAgency || ''} onChange={handleChange} name="idAgency" label="Agency Name" select fullWidth>
                      {data.agents?.length > 0 ? (
                        data.agencies.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No Agencies Avaliable</MenuItem>
                      )}
                    </TextField>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography component="div" variant="h5">
                Agents
              </Typography>
              <Grid sx={{ justifyContent: 'space-between' }}>
                <Tooltip title="Edit Agents" placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="Create"
                      size="large"
                      sx={{ mr: 'auto', display: 'inline-flex' }}
                      disabled={!state?.idAgent}
                      onClick={() =>
                        handleEdit(
                          'agentOpen',
                          data.agents.find((item) => item.id === state.idAgent)
                        )
                      }
                    >
                      <Edit fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Add Agents" placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="Create"
                      size="large"
                      sx={{ mr: 'auto', display: 'inline-flex' }}
                      disabled={!state?.idAgency}
                      onClick={() => handleCreate('agentOpen')}
                    >
                      <Add fontSize="large" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    <TextField
                      value={state?.idAgent || ''}
                      onChange={handleChange}
                      name="idAgent"
                      fullWidth
                      select
                      label="Agent's Display Name"
                      error={!state?.idAgent}
                      helperText={!state?.idAgent && 'Agents cannot be Empty'}
                    >
                      {data.agents?.length > 0 ? (
                        data.agents?.filter((item) => item?.idAgency === dataId?.idAgency)?.length > 0 ? (
                          data.agents
                            ?.filter((item) => item?.idAgency === dataId?.idAgency)
                            ?.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user?.displayName}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem>No Agents Please add one</MenuItem>
                        )
                      ) : (
                        <MenuItem>No Agents Please add one</MenuItem>
                      )}
                    </TextField>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="div" variant="h5">
                    Insurance
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="span" variant="subtitle2">
                    <Autocomplete
                      fullWidth
                      options={data.insurances}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      readOnly={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                      value={data.insurances?.find((type) => type.id === state?.idInsurance) || null}
                      onChange={(_, newValue) => {
                        const selectedInsuranceId = newValue ? newValue.id : null;
                        handleChange({ target: { name: 'idInsurance', value: selectedInsuranceId } });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          {selected.selectedCustomerOrg?.id !== undefined &&
            (showAircraft ? (
              <AircraftQuoteComponent
                companyId={companyId}
                activeStep={0}
                selectedAircraft={selected.selectedAircraft}
                selectedCustomerOrg={selected.selectedCustomerOrg}
                selectedCustomerContact={selectedObj.selectedCustomerContact}
                emitSelectedAircraft={handleAircraftSelect}
              />
            ) : (
              <>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5">
                          Selected Aircraft: {selected.activeAircraft.registrationNo}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {showRelations ? (
                  <>
                    {selected.activeAircraft?.id !== undefined && (
                      <AircraftQuoteLienHolder
                        activeStep={0}
                        selectedInterests={selected.selectedInterests}
                        currentAircraft={selected.activeAircraft}
                        companyId={companyId}
                        emitSelectedInterest={handleInterestSelect}
                      />
                    )}
                    <Divider sx={{ margin: '20px 0' }} />
                    {selected.selectedCustomerOrg?.id !== undefined && selected.activeAircraft?.id !== undefined && (
                      <AircraftQuotePilots
                        companyId={companyId}
                        activeStep={0}
                        selectedPilots={selected.selectedPilots}
                        selectedCustomerOrg={selected.selectedCustomerOrg}
                        selectedAircraft={selected.activeAircraft}
                        emitSelectedPilot={handlePilotSelect}
                      />
                    )}
                  </>
                ) : (
                  <>{selected.activePilot?.id && <AircraftQuotePilotHours companyId={companyId} selectedPilot={selected.activePilot} />}</>
                )}
                <Grid container spacing={3} sx={{ marginTop: 5 }}>
                  <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={() => (showRelations ? handleSaveRelations() : setShowRelations(true))}
                        >
                          {showRelations ? 'Save Aircraft Relations' : 'Save Pilot Hours'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ))}
        </CardContent>
        <Divider />
      </Card>
      <Card sx={{ marginTop: 4 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={
                      !state?.idCustomerOrganization ||
                      !state?.idAgent ||
                      !state?.idAgency ||
                      selected.selectedAircraft?.length === 0 ||
                      !showAircraft
                    }
                    onClick={handlePolicySave}
                  >
                    Save Quote Request
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default RequestView;
