import React, { useEffect, useState } from 'react';
// material-ui
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  MenuItem,
  Autocomplete
} from '@mui/material';

// project import
import { gridSpacing } from 'config.js';
import { states } from 'utils/states';
import { Transition } from 'utils/transition';

// assets
import { useParams } from 'react-router';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';


// eslint-disable-next-line react/prop-types
const LienholderDialog = ({ open, handleClosedialog, handleLienholderSave, actionType, lienholderState, lienholderRelations = [] }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [lienholder, setLienholder] = useState({});
  const [lienholders, setLienholders] = useState([]);
  const { companyId } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLienholder({
      ...lienholder,
      [name]: value
    });
  };

  const handleSave = () => {
    let returnLienholder;

    if (actionType !== 'Details') {
      returnLienholder = {
        lienholder: {
          name: lienholder?.name,
          address1: lienholder?.address1,
          address2: lienholder?.address2,
          city: lienholder?.city,
          state: lienholder?.state,
          zip: lienholder?.zip,
          idCompany: +companyId
        }
      };
    } else {
      returnLienholder = {
        lienholder: {
          idLienHolder: lienholder?.idLienHolder
        }
      };
    }

    return handleLienholderSave(returnLienholder);
  };

  useEffect(() => {
    const getLienholders = async () => {
      const relations = lienholderRelations?.map((lien) => lien.LienHolder.id) || [];
      const response = await axios.get(`/api/lienholder/companyId/${companyId}`);
      let tempArray = [...response.data];
      tempArray = tempArray.filter((lien) => (relations.includes(lien.id) ? false : true));
      setLienholders(tempArray);
    };
    if (lienholderState) {
      setLienholder({ ...lienholderState });
    }

    if (actionType === 'Details') {
      getLienholders();
    }
  }, [lienholderState, companyId]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosedialog}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              minWidth: 200,
              maxHeight: '100%',
              height: '100vh'
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create' || actionType === 'Details'
            ? 'New Lien Holder'
            : actionType === 'Edit'
            ? 'Edit Lien Holder'
            : actionType === 'Delete'
            ? 'Delete Lien Holder'
            : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            actionType === 'Details' ? (
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ mt: 1 }}
                    fullWidth
                    options={lienholders}
                    getOptionLabel={(option) => ` ${option.id}: ${option.name}`}
                    value={lienholders.find((pil) => pil.id === lienholders.idLienHolder) || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      const selectedLienholderId = newValue ? newValue.id : null;
                      handleChange({ target: { name: 'idLienHolder', value: selectedLienholderId } });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!lienholder?.idLienHolder}
                        helperText={!lienholder?.idLienHolder ? 'Cannot Be Empty' : ''}
                        label="LienHolders"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    sx={{ mt: 1 }}
                    name="name"
                    label="Name"
                    variant="outlined"
                    error={!lienholder?.name?.trim()}
                    helperText={!lienholder?.name?.trim() ? 'Invalid Name' : ''}
                    value={lienholder?.name || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    sx={{ mt: 1 }}
                    name="address1"
                    label="Address 1"
                    variant="outlined"
                    error={!lienholder?.address1?.trim()}
                    helperText={!lienholder?.address1?.trim() ? 'Invalid Address' : ''}
                    value={lienholder?.address1 || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    name="address2"
                    label="Address 2"
                    variant="outlined"
                    value={lienholder?.address2 || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    name="city"
                    label="City"
                    variant="outlined"
                    value={lienholder?.city || ''}
                    error={!lienholder?.city?.trim()}
                    helperText={!lienholder?.city?.trim() ? 'Invalid Type' : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    fullWidth
                    id="State"
                    value={lienholder?.state || ''}
                    error={!lienholder?.state}
                    helperText={!lienholder?.state ? 'Cannot be Empty' : ''}
                    onChange={handleChange}
                    label="State"
                    name="state"
                    sx={{ mt: 1 }}
                  >
                    {states.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    name="zip"
                    label="Zipcode"
                    variant="outlined"
                    error={!lienholder?.zip?.trim()}
                    helperText={!lienholder?.zip?.trim() ? 'Invalid Zipcode' : ''}
                    value={lienholder?.zip || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            )
          ) : (
            <DialogContentText>Confirm if you want to delete the this lienHolder</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              actionType !== 'Delete'
                ? actionType === 'Details'
                  ? !lienholder?.idLienHolder
                  : !lienholder.address1 || !lienholder.name || !lienholder.city || !lienholder.state || !lienholder.zip
                : false
            }
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClosedialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LienholderDialog;
