import {
  Tooltip,
  IconButton,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  DialogContentText,
  Button,
  InputAdornment,
  Input,
  MenuItem
} from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';

import { validRegex, phoneNumberRegex, formatDate } from 'utils/FormatUtils';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CustomTable from 'component/CustomTable';

import initializeAxios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { gridSpacing } from 'config.js';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';
import { Transition } from 'utils/transition';

const columns = [
  {
    id: 'id',
    label: 'Id'
  },
  {
    id: 'customerContact',
    label: 'Customers Name'
  },
  {
    id: 'agentDisplayName',
    label: "Agent's Display Name"
  },
  {
    id: 'claimDate',
    label: 'Claim Date'
  },
  {
    id: 'type',
    label: 'Claim Status'
  }
];


const PolicyClaims = ({ isLoading, policyId, aircrafts, users, customerContacts, customerOrgId }) => {
  const auth = useAuth();
  // eslint-disable-next-line no-unused-vars
  const axios = initializeAxios(auth);
  const navigate = useNavigate();

  const [claim, setClaim] = useState({});
  const [claims, setClaims] = useState([]);
  const [actionType, setActionType] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [claimId, setClaimId] = useState(null);
  const [claimStatus, setClaimStatus] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agencyId, setAgencyId] = useState(null);
  const [aircraftId, setAircraftId] = useState(null);
  const [aircraftList, setAircraftList] = useState([]);
  const [open, setOpen] = useState(false);
  const { companyId } = useParams();

  const handleClickOpenDialog = () => {
    setOpen(true);
    setActionType('Create');
  };

  const handleEdit = (editClaim) => {
    setClaim({ ...editClaim });
    setClaimId(editClaim.id);
    setAgencyId(editClaim?.idAgency);
    setAircraftId(editClaim?.idAircraft);
    setActionType('Edit');
    setOpen(true);
  };

  const handleDelete = (deleteClaim) => {
    setClaimId(deleteClaim.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setClaim({});
  };

  const handleSave = async () => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/claim/companyId/${companyId}`, {
          claim: {
            idAgent: +claim?.idAgent,
            idAgency: +claim?.idAgency,
            idPilot: claim?.idPilot,
            idCompanyUser: claim?.idCompanyUser,
            idAircraft: claim?.idAircraft,
            idCustomerContact: claim?.idCustomerContact,
            claimDate: claim?.claimDate,
            idClaimStatus: claim?.idClaimStatus,
            location: claim?.location,
            description: claim?.description,
            phone: claim?.phone,
            email: claim?.email,
            notes: claim?.notes,
            hullDamage: claim?.hullDamage,
            bodilyInjury: claim?.bodilyInjury,
            propertyDamage: claim?.propertyDamage,
            adjustmentExpense: claim?.adjustmentExpense,
            allocatedReserve: claim?.allocatedReserve,
            recovery: claim?.recovery,
            idPolicy: +policyId
          }
        });
        setClaims([...claims, response.data]);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/claim/${claimId}/companyId/${companyId}`, {
          claim: {
            idAgent: claim?.idAgent,
            idAgency: claim?.idAgency,
            idPilot: claim?.idPilot,
            idCompanyUser: claim?.idCompanyUser,
            idAircraft: claim?.idAircraft,
            idCustomerContact: claim?.idCustomerContact,
            claimDate: claim?.claimDate,
            idClaimStatus: claim?.idClaimStatus,
            location: claim?.location,
            description: claim?.description,
            phone: claim?.phone,
            email: claim?.email,
            notes: claim?.notes,
            hullDamage: claim?.hullDamage,
            bodilyInjury: claim?.bodilyInjury,
            propertyDamage: claim?.propertyDamage,
            adjustmentExpense: claim?.adjustmentExpense,
            allocatedReserve: claim?.allocatedReserve,
            recovery: claim?.recovery
          }
        });
        const tempArray = [...claims];
        const index = tempArray.findIndex((e) => e.id === claimId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setClaims(tempArray);
        break;
      }
      case 'Delete': {
        const response = await axios.delete(`/api/claim/${claimId}/companyId/${companyId}`);
        const tempArray = [...claims];
        const index = tempArray.findIndex((e) => e.id === claimId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setClaims(tempArray);
        break;
      }
    }
    handleClosedialog();
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'idAgency') {
      setAgencyId(value);
      setClaim({
        ...claim,
        idAgent: '',
        [name]: value
      });
    } else if (name === 'idAircraft') {
      setAircraftId(value);
      setClaim({
        ...claim,
        idPilot: '',
        [name]: value
      });
    } else {
      setClaim({
        ...claim,
        [name]: name === 'accept' ? checked : value
      });
    }
  };

  const handleDateChange = (date) => {
    setClaim({
      ...claim,
      claimDate: date !== '' ? date : null
    });
  };

  const handleGoToDetail = (claimDetail) => {
    navigate(`/company/${companyId}/policy/${policyId}/claim/${claimDetail.id}`, {
      state: { customerOrgId: customerOrgId, policyId: policyId }
    });
  };

  useEffect(() => {
    const getClaims = async () => {
      const response = await axios.get(`/api/claim/policyId/${policyId}/companyId/${companyId}`);
      setClaims([...response.data]);
      setAircraftList([...aircrafts]);
    };
    getClaims();
  }, [policyId, companyId]);
  useEffect(() => {
    const getClaimStatus = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=ClaimStatus`);
      setClaimStatus([...response.data]);
    };
    const getAgencies = async () => {
      const response = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies([...response.data]);
    };

    const getAgents = async () => {
      const response = await axios.get(`/api/agent/companyId/${companyId}`);
      setAgents([...response.data]);
    };
    getClaimStatus();
    getAgencies();
    getAgents();
  }, []);

  return (
    <>
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        data={claims}
        hasPagination={false}
        title="Claims"
        hasCreateButton={true}
        isDisabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
        handleOpenCreate={handleClickOpenDialog}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Claim" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  onClick={() => handleEdit(claims[index])}
                  disabled={(row.idClaimStatus === 31 && !auth.user.isSuperAdmin) || !auth.writePrivledge(auth.user, 'Policy_Adm')}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Claim" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                  onClick={() => handleDelete(claims[index])}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Claim Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(claims[index])}>
                <TextSnippetIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosedialog}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              maxHeight: '100%',
              height: '100vh'
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create' ? 'New Claim' : actionType === 'Edit' ? 'Edit Claim' : actionType === 'Delete' ? 'Delete Claim' : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Aircraft"
                  name="idAircraft"
                  select
                  value={claim?.idAircraft || ''}
                  error={!claim?.idAircraft}
                  helperText={!claim?.idAircraft ? 'Cannot be Empty' : ''}
                  onChange={handleChange}
                >
                  {deactiveArrayUtil(
                    aircraftList,
                    {
                      idAircraft: claim?.idAircraft,
                      Aircraft: {
                        id: claim?.idAircraft,
                        registrationNo: claim?.Aircraft?.registrationNo
                      }
                    },
                    'idAircraft'
                  )?.map((aircraft) => (
                    <MenuItem key={aircraft?.idAircraft} value={aircraft?.idAircraft}>
                      {aircraft?.Aircraft?.registrationNo}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Pilot"
                  name="idPilot"
                  select
                  value={claim?.idPilot || ''}
                  error={!claim?.idPilot}
                  helperText={!claim?.idPilot ? 'Cannot be Empty' : ''}
                  onChange={handleChange}
                >
                  {aircraftId
                    ? deactiveArrayUtil(
                        aircraftList,
                        {
                          idPilot: claim?.idPilot,
                          PilotDetails: {
                            idPilot: claim?.idPilot,
                            firstname: claim?.Pilot?.firstname,
                            lastname: claim?.Pilot?.lastname
                          }
                        },
                        'idPilot'
                      )
                        .filter((item) => item.idAircraft === aircraftId)[0]
                        ?.AircraftPilotDetails.map((pilot) => (
                          <MenuItem key={pilot?.PilotDetails?.idPilot} value={pilot?.PilotDetails?.idPilot}>
                            {`${pilot?.PilotDetails?.firstname} ${pilot?.PilotDetails?.lastname}`}
                          </MenuItem>
                        ))
                    : []}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Location"
                  name="location"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={claim?.location || ''}
                  error={!claim?.location?.trim()}
                  helperText={!claim?.location?.trim() ? 'location cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="description"
                  name="description"
                  variant="outlined"
                  multiline
                  rows={6}
                  value={claim?.description || ''}
                  error={!claim?.description?.trim()}
                  helperText={!claim?.description?.trim() ? 'Description cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Customer Contact"
                  name="idCustomerContact"
                  select
                  value={claim?.idCustomerContact || ''}
                  error={!claim?.idCustomerContact}
                  helperText={!claim?.idCustomerContact ? 'Cannot be Empty' : ''}
                  onChange={handleChange}
                >
                  {deactiveArrayUtil(customerContacts, {
                    id: claim?.idCustomerContact,
                    name: claim?.CustomerContact?.name
                  }).map((cc) => (
                    <MenuItem key={cc.id} value={cc.id}>
                      {cc.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Customer Email"
                  name="email"
                  variant="outlined"
                  input={<Input />}
                  value={claim?.email || ''}
                  error={claim?.email?.length > 0 ? !claim?.email?.match(validRegex) : false}
                  helperText={claim?.email?.length > 0 ? (!claim?.email?.match(validRegex) ? 'Invalid Email' : '') : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Customer Phone"
                  name="phone"
                  variant="outlined"
                  value={claim.phone || ''}
                  onChange={handleChange}
                  error={claim?.phone?.length > 0 ? !claim?.phone?.match(phoneNumberRegex) : false}
                  helperText={claim?.phone?.length > 0 ? (!claim?.phone?.match(phoneNumberRegex) ? 'Invalid Phone Number' : '') : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  inputFormat="MM/dd/yyyy"
                  margin="normal"
                  label="Date of Claim"
                  renderInput={(props) => (
                    <TextField fullWidth error={!claim?.claimDate} helperText={!claim?.claimDate ? 'Cannot be Empty' : ''} {...props} />
                  )}
                  value={formatDate(claim?.claimDate) || null}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  keyboardIcon={<EventTwoToneIcon />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Agency Name"
                  name="idAgency"
                  select
                  value={claim?.idAgency || ''}
                  error={!claim?.idAgency}
                  helperText={!claim?.idAgency ? 'Cannot be Empty' : ''}
                  onChange={handleChange}
                >
                  {deactiveArrayUtil(agencies, {
                    id: claim?.idAgency,
                    name: claim?.Agency?.name
                  })?.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Agent Display Name"
                  name="idAgent"
                  select
                  value={claim?.idAgent || ''}
                  disabled={!claim?.idAgency}
                  error={!claim?.idAgent}
                  helperText={!claim?.idAgent ? 'Cannot be Empty' : ''}
                  onChange={handleChange}
                >
                  {deactiveArrayUtil(agents, {
                    id: claim?.idAgent,
                    displayName: claim?.Agent?.displayName
                  })
                    ?.filter((item) => item.idAgency === agencyId)
                    ?.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.displayName}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Company User Name"
                  name="idCompanyUser"
                  select
                  value={claim?.idCompanyUser || ''}
                  error={!claim?.idCompanyUser}
                  helperText={!claim?.idCompanyUser ? 'Cannot be Empty' : ''}
                  onChange={handleChange}
                >
                  {deactiveArrayUtil(users, {
                    id: claim?.idCompanyUser,
                    displayName: claim?.['UserProfile_Claim_idCompanyUserToUserProfile']?.displayName
                  })?.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.displayName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Hull Damage"
                  id="filled-start-adornment1"
                  name="hullDamage"
                  value={claim?.hullDamage || ''}
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Bodily Injury"
                  id="filled-start-adornment1"
                  name="bodilyInjury"
                  value={claim?.bodilyInjury || ''}
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Proprty Damage"
                  id="filled-start-adornment1"
                  name="propertyDamage"
                  value={claim?.propertyDamage || ''}
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Allocated Reserve"
                  id="filled-start-adornment1"
                  name="allocatedReserve"
                  value={claim?.allocatedReserve || ''}
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Adjustment Expense"
                  id="filled-start-adornment1"
                  name="adjustmentExpense"
                  value={claim?.adjustmentExpense || ''}
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Recovery"
                  id="filled-start-adornment1"
                  name="recovery"
                  value={claim?.recovery || ''}
                  type="number"
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Notes"
                  name="notes"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={claim?.notes || ''}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Claim Status"
                  name="idClaimStatus"
                  select
                  value={claim?.idClaimStatus || ''}
                  error={!claim?.idClaimStatus}
                  helperText={!claim?.idClaimStatus ? 'Cannot be Empty' : ''}
                  onChange={handleChange}
                >
                  {claimStatus?.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.val}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          ) : (
            <DialogContentText>Confirm if you want to Cancel this Claim</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              actionType !== 'Delete'
                ? !claim?.idAgent ||
                  !claim?.idAgency ||
                  !claim?.idCompanyUser ||
                  !claim?.idAircraft ||
                  !claim?.claimDate ||
                  !claim?.idCustomerContact ||
                  !claim?.idClaimStatus ||
                  !claim?.location?.trim()?.length ||
                  !claim?.description?.trim()?.length
                : false
            }
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClosedialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PolicyClaims;
