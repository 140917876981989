/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// material-ui
import { Grid, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

// project import
import { gridSpacing } from 'config.js';
import { Transition } from 'utils/transition';

// assets
import { useParams } from 'react-router';


// eslint-disable-next-line react/prop-types
const PolicyOptionAircraftDialog = ({
  open,
  handleClosedialog,
  handlePolicyOptionAircraftSave,
  actionType,
  policyOptionAircraft,
  title
}) => {
  const [newPolicyOptionAircraft, setNewPolicyOptionAircraft] = useState({});
  const { companyId } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPolicyOptionAircraft({
      ...newPolicyOptionAircraft,
      [name]: value
    });
  };

  const handleSave = () => {
    let returnPolicyOptionAircraft = {
      policyOptionAircraft: {
        shortCode: newPolicyOptionAircraft.shortCode,
        description: newPolicyOptionAircraft.description || '',
        idCompany: +companyId
      }
    };

    const switchTitle = title.split(' ')[3];
    switch (switchTitle) {
      case 'Liability': {
        returnPolicyOptionAircraft = {
          policyOptionAircraft: {
            ...returnPolicyOptionAircraft.policyOptionAircraft,
            perPerson: +newPolicyOptionAircraft?.perPerson,
            perOccurence: +newPolicyOptionAircraft?.perOccurence,
            perPassenger: +newPolicyOptionAircraft?.perPassenger
          }
        };
        break;
      }
      case 'Medical': {
        console.log(newPolicyOptionAircraft);

        returnPolicyOptionAircraft = {
          policyOptionAircraft: {
            ...returnPolicyOptionAircraft.policyOptionAircraft,
            perPerson: parseInt(newPolicyOptionAircraft?.perPerson)
          }
        };
        break;
      }
    }
    handlePolicyOptionAircraftSave(returnPolicyOptionAircraft);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setNewPolicyOptionAircraft({});
    handleClosedialog();
  }

  useEffect(() => {
    if (policyOptionAircraft) {
      setNewPolicyOptionAircraft((prev) => ({ ...prev, ...policyOptionAircraft }));
    }
  }, [policyOptionAircraft, companyId]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              minWidth: 200,
              maxHeight: '100%',
              height: '100vh',
              width: { xs: '100vw' }
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create'
            ? `New ${title}`
            : actionType === 'Edit'
            ? `Edit ${title}`
            : actionType === 'Delete'
            ? `Delete ${title}`
            : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Short Code"
                  name="shortCode"
                  variant="outlined"
                  required
                  value={newPolicyOptionAircraft?.shortCode || ''}
                  error={!newPolicyOptionAircraft?.shortCode?.trim()}
                  helperText={!newPolicyOptionAircraft?.shortCode?.trim() ? 'Short cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
              {['Liability', 'Medical'].some((item) => title.includes(item)) && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Per Person"
                      name="perPerson"
                      variant="outlined"
                      type="number"
                      value={newPolicyOptionAircraft?.perPerson || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  {title.includes('Liability') && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Per Passenger"
                          name="perPassenger"
                          variant="outlined"
                          type="number"
                          value={newPolicyOptionAircraft?.perPassenger || ''}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Per Occurrence"
                          name="perOccurence"
                          variant="outlined"
                          type="number"
                          value={newPolicyOptionAircraft?.perOccurence || ''}
                          onChange={handleChange}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Description"
                  name="description"
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={newPolicyOptionAircraft?.description || ''}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          ) : (
            <DialogContentText>Confirm if you want to delete the this Policy Option Aircraft</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={actionType !== 'Delete' ? !newPolicyOptionAircraft?.shortCode?.trim() : false}
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClosedialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PolicyOptionAircraftDialog;
