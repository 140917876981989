import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'component/LoadingOverlay';
import PilotDialog from 'component/PilotDialog';

const columns = [
  {
    id: 'fullName',
    label: 'Name'
  },
  {
    id: 'age',
    label: 'Age (years)'
  },
  {
    id: 'list',
    label: 'Pilot Certifications'
  }
];

const AircraftQuotePilots = ({ companyId, activeStep, selectedPilots, selectedAircraft, selectedCustomerOrg, emitSelectedPilot }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pilots, setPilots] = useState([]);
  const [actionType, setActionType] = useState('');
  const [pilot, setPilot] = useState({});
  const [pilotId, setPilotId] = useState(null);

  const handleClickOpendialog = () => {
    setActionType('CreateWorkflow');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setPilot({});
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const { id, ...rest } = row;
    setPilotId(id);
    setActionType('EditWorkflow');
    setPilot({ ...rest, PilotCerts: rest.PilotCerts.map((cert) => cert.Options.id),
      pilotRatings: rest?.PilotRating?.map(rate => rate.Options.id) });
    setOpen(true);
  };

  const handleSave = async (PilotDialog) => {
    setIsLoading(true);
    const pilot = { ...PilotDialog.pilot, idCustomerOrganization: +selectedCustomerOrg.id };
    let response;
    switch (actionType) {
      case 'CreateWorkflow': {
        response = await axios.post(`/api/pilots/companyId/${companyId}`, {
          pilot,
          pilotCerts: PilotDialog?.pilotCerts,
          pilotRatings: PilotDialog?.pilotRatings || [],
          detailsFlag: false
        });
        emitSelectedPilot(activeStep, response.data);
        break;
      }
      case 'EditWorkflow': {
        response = await axios.put(`/api/pilots/${pilotId}/companyId/${companyId}`, {
          pilot,
          pilotCerts: PilotDialog?.pilotCerts,
          pilotRatings: PilotDialog?.pilotRatings || [],
          detailsFlag: false
        });
        const tempArray = [...pilots];
        const index = tempArray.findIndex((item) => item.id === pilotId);
        tempArray[index] = response.data;
        setPilots(tempArray);
      }
    }
    handleClosedialog();
    setIsLoading(false);
  };

  const handlePilotSelect = (row) => {
    emitSelectedPilot(activeStep, row);
  };

  useEffect(() => {
    const getPilots = async () => {
      setIsLoading(true);
      const result = await axios.get(
        `/api/pilots/companyId/${companyId}/customerOrgId/${selectedCustomerOrg.id}/workflow/aircraftId/${selectedAircraft.id}`
      );
      setPilots(result.data);
      setIsLoading(false);
    };

    getPilots();
  }, [companyId, selectedCustomerOrg, selectedAircraft]);

  return (
    <>
      <PilotDialog open={open} handleClosedialog={handleClosedialog} handlePilotSave={handleSave} actionType={actionType} pilot={pilot} />
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        data={pilots}
        isSearch={true}
        hasClickableRows={true}
        emitClickedRow={handlePilotSelect}
        title="Pilots"
        selectedRows={[...selectedPilots]}
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label={selectedPilots.find((item) => item.id === row.id) ? 'Unselect' : 'Select'}
              color="primary"
              variant={selectedPilots.find((item) => item.id === row.id) ? 'filled' : 'outlined'}
              sx={{ color: selectedPilots.find((item) => item.id === row.id) ? '#fff' : '#000', marginRight: 1 }}
              clickable
              onClick={() => handlePilotSelect(row)}
            />
            <Chip
              label="Edit"
              color="primary"
              variant="filled"
              sx={{ color: '#fff', width: '40%' }}
              clickable
              onClick={(e) => handleEdit(e, row)}
            />
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuotePilots;
