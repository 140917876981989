import React, { useState } from 'react';
import Breadcrumb from 'component/Breadcrumb';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import CustomStepper from 'component/CustomStepper';
import AgencyQuote from 'component/AgencyQuote';

const AircraftRequest = () => {
  const { companyId } = useParams();
  //   const steps = ['Named Insured', 'Agency', 'Agent', 'Aircraft', 'Insurance'];
  const [selectedAgency, setSelectedAgency] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const componentSteps = [
    {
      id: 1,
      name: 'Named Insured',
      getComponent: () => (
        <AgencyQuote
          companyId={companyId}
          selectedAgency={selectedAgency}
          activeStep={activeStep}
          emitSelectedAgency={handleSelect}
          handleNext={handleNext}
        />
      )
    }
  ];

  const handleNext = () => {
    setActiveStep(2);
  }

  const handleSelect = (emitActiveStep, row) => {
    console.log('row', row, 'emitActiveStep', emitActiveStep);
    setActiveStep(1);
    setSelectedAgency(row);
    emitActiveStep();
  };

  return (
    <>
      <Breadcrumb title="Aircraft Request" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Aircraft Request
        </Typography>
      </Breadcrumb>
      <CustomStepper componentSteps={componentSteps} />
    </>
  );
};
export default AircraftRequest;
