import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { states } from 'utils/states';

import { Transition } from 'utils/transition';

const CompanyDialog = ({ open, company, handleClose, actionType, handleSave }) => {
  const [newCompany, setNewCompany] = useState({});
  const handleChange = (event) => {
    const { value, name } = event.target;
    setNewCompany({
      ...newCompany,
      [name]: value
    });
  };

  const handleSaveCompany = () => {
    handleSave(newCompany);
  };

  const handleClosedialog = () => {
    setNewCompany({});
    handleClose();
  };

  useEffect(() => {
    if (Object.values(company)?.length > 0) {
      const tempCompany = { ...company };
      setNewCompany(tempCompany);
    }
  }, [company]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClosedialog}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh',
            width: { xs: '100vw' }
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' ? 'New Company' : actionType === 'Edit' ? 'Edit Company' : actionType === 'Delete' ? 'Delete Company' : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label="Company Name"
              name="name"
              variant="outlined"
              error={!newCompany.name}
              helperText={!newCompany.name ? 'Company Name can not be empty' : ''}
              value={newCompany.name || ''}
              onChange={handleChange}
            />
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label="Address 1"
              name="address1"
              variant="outlined"
              value={newCompany.address1 || ''}
              onChange={handleChange}
            />
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label="Address 2"
              name="address2"
              variant="outlined"
              value={newCompany.address2 || ''}
              onChange={handleChange}
            />
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label="City"
              name="city"
              variant="outlined"
              value={newCompany.city || ''}
              onChange={handleChange}
            />
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              select
              label="State"
              name="state"
              variant="outlined"
              value={newCompany.state || ''}
              onChange={handleChange}
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label="Zip"
              name="zip"
              variant="outlined"
              value={newCompany.zip || ''}
              onChange={handleChange}
            />
          </>
        ) : (
          <DialogContentText>Confirm if you want to delete this newCompany</DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="actions">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveCompany}
          disabled={actionType !== 'Delete' ? !newCompany.name : null}
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClosedialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyDialog;
