// react Imports
import React, { useEffect, useState } from 'react';
// Mui Imports
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Input,
  FormControl,
  // InputLabel,
  // Select,
  MenuItem,
  // FormHelperText,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContentText,
  Autocomplete,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { states } from 'utils/states';
import { validRegex, phoneNumberRegex } from 'utils/FormatUtils';

// Local Imports
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import { useParams } from 'react-router';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
dayjs.extend(CustomParseFormat);
import { Transition } from 'utils/transition';

const CustomDialogTitle = styled((props) => <DialogTitle {...props} />)(() => ({
  padding: 0
}));

const AircraftDialog = ({
  open,
  handleClosedialog,
  aircraft,
  handleAircraftSave,
  customerOrg = [],
  selectedCustomerOrg = {},
  selectedContact = {},
  actionType,
  customerOrgId = null,
  aircraftRelations = [],
  isPolicyAircraft = false,
  isQuote = false
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [newAircraft, setNewAircraft] = useState({});
  // const [pilots, setPilots] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);

  const [aircraftLiabilities, setAircraftLiabilities] = useState([]);
  const [aircraftHulls, setAircraftHulls] = useState([]);
  const [aircraftMedicals, setAircraftMedicals] = useState([]);
  const [aircraftUses, setAircraftUses] = useState([]);
  const [aircraftTerritories, setAircraftTerritories] = useState([]);
  const [aircraftWarTrias, setAircraftWarTrias] = useState([]);

  const { companyId } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'idCustomerOrganization') {
      const customerOrgItem = customerOrg.find((item) => item.id === value);
      setNewAircraft({
        ...newAircraft,
        idCustomerOrganization: value,
        registeredOwner: customerOrgItem?.name,
        registeredAddress1: customerOrgItem?.address1,
        registeredAddress2: customerOrgItem?.address2,
        registeredCity: customerOrgItem?.city,
        registeredState: customerOrgItem?.state,
        registeredZip: customerOrgItem?.zip
      });
    } else {
      setNewAircraft({
        ...newAircraft,
        [name]: value
      });
    }
  };

  const handleSave = () => {
    let returnAircraft;
    if (customerOrgId && !isPolicyAircraft) {
      returnAircraft = {
        aircraft: {
          idAircraft: newAircraft.idAircraft
        }
      };
    } else if (isPolicyAircraft) {
      returnAircraft = {
        aircraft: {
          idAircraft: newAircraft?.idAircraft,
          idLiability: newAircraft?.idLiability,
          idLiabilityAmount: newAircraft?.idLiabilityAmount || null,
          idMedical: newAircraft?.idMedical,
          idMedicalAmount: newAircraft?.idMedicalAmount || null,
          idHull: newAircraft?.idHull,
          idHullAmount: newAircraft?.idHullAmount || null,
          idUse: newAircraft?.idUse,
          idTerritory: newAircraft?.idTerritory,
          idWarTria: newAircraft?.idWarTria,
          taxesFees: newAircraft?.taxesFees,
          idHullIMDeductible: newAircraft?.idHullIMDeductible,
          idHullNIMDeductible: newAircraft?.idHullNIMDeductible
        }
      };
    } else {
      returnAircraft = {
        aircraft: {
          registrationNo: newAircraft.registrationNo,
          hangered: newAircraft.hangered === 'true' ? true : false,
          make: newAircraft.make,
          seats: +newAircraft.seats,
          type: newAircraft.type,
          value: +newAircraft.value,
          year: +newAircraft.year,
          airportCode: newAircraft.airportCode,
          idCustomerOrganization: newAircraft.idCustomerOrganization,
          registeredOwner: newAircraft.registeredOwner,
          registeredEmail: newAircraft.registeredEmail,
          registeredPhone: newAircraft.registeredPhone,
          registeredAddress1: newAircraft.registeredAddress1,
          registeredAddress2: newAircraft.registeredAddress2 || '',
          registeredCity: newAircraft.registeredCity,
          registeredState: newAircraft.registeredState,
          registeredZip: newAircraft.registeredZip,
          isLeased: newAircraft?.isLeased === 'true' ? true : false
        }
      };
    }
    handleAircraftSave(returnAircraft);
  };

  const handleDateChange = (event) => {
    let year;
    if (event) {
      if (dayjs(event).isValid()) {
        year = event?.format('YYYY');
      } else {
        year = '';
      }
    }

    setNewAircraft({
      ...newAircraft,
      year
    });
  };

  const handleClose = () => {
    setNewAircraft({});
    handleClosedialog();
  };

  useEffect(() => {
    // const getPilots = async () => {
    //   const result = await axios.get(`/api/pilots/companyId/${companyId}/all`);
    //   setPilots(result.data);
    // };
    if (Object.values(aircraft).length > 0) {
      const propAircraft = { ...aircraft };
      setNewAircraft(propAircraft);
    }
    if (actionType === 'CreateWorkflow') {
      setNewAircraft({
        idCustomerOrganization: selectedCustomerOrg?.id,
        registeredOwner: selectedCustomerOrg?.name,
        registeredPhone: selectedContact?.phone,
        registeredEmail: selectedContact?.email,
        registeredAddress1: selectedCustomerOrg?.address1,
        registeredAddress2: selectedCustomerOrg?.address2,
        registeredCity: selectedCustomerOrg?.city,
        registeredState: selectedCustomerOrg?.state,
        registeredZip: selectedCustomerOrg?.zip
      });
    }
    // getPilots();
  }, [aircraft, actionType]);

  useEffect(() => {
    const getLiabilities = async () => {
      const response = await axios.get(`/api/poaLiability/companyId/${companyId}`);
      setAircraftLiabilities([...response.data]);
    };

    const getHulls = async () => {
      const response = await axios.get(`/api/poaHull/companyId/${companyId}`);
      setAircraftHulls([...response.data]);
    };

    const getMedical = async () => {
      const response = await axios.get(`/api/poaMedical/companyId/${companyId}`);
      setAircraftMedicals([...response.data]);
    };

    const getUse = async () => {
      const response = await axios.get(`/api/poaUse/companyId/${companyId}`);
      setAircraftUses([...response.data]);
    };

    const getTerritory = async () => {
      const response = await axios.get(`/api/poaTerritory/companyId/${companyId}`);
      setAircraftTerritories([...response.data]);
    };

    const getWarTria = async () => {
      const response = await axios.get(`/api/poaWarTria/companyId/${companyId}`);
      setAircraftWarTrias([...response.data]);
    };
    getLiabilities();
    getHulls();
    getMedical();
    getTerritory();
    getWarTria();
    getUse();
  }, [companyId]);
  const saveInput = React.createRef();

  useEffect(() => {
    const getAircrafts = async () => {
      const relations = aircraftRelations?.map((air) => air.Aircraft.id) || [];
      const result = await axios.get(`/api/aircraft/companyId/${companyId}/customerOrgId/${customerOrgId}`);
      let tempArray = [...result.data];
      tempArray = tempArray.filter((air) => (relations.includes(air.id) ? false : true));
      setAircrafts(tempArray);
    };

    if (customerOrgId) {
      getAircrafts();
    }
  }, [customerOrgId, aircraftRelations]);

  return (
    <Dialog
      open={open}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          height: '100vh',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            minWidth: 200,
            maxHeight: '100vh',
            height: '100vh',
            width: { xs: '100vw' }
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' || (actionType === 'Details' && newAircraft?.actionType !== 'Edit') || actionType === 'CreateWorkflow'
          ? 'New Aircraft'
          : actionType === 'Edit' || newAircraft?.actionType === 'Edit'
          ? 'Edit Aircraft'
          : actionType === 'Delete'
          ? 'Delete Aircraft'
          : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          actionType === 'Details' ? (
            <Grid container spacing={gridSpacing}>
              {newAircraft?.actionType !== 'Edit' && (
                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ mt: 1 }}
                    fullWidth
                    options={aircrafts}
                    getOptionLabel={(option) => ` ${option.id}: ${option.registrationNo}`}
                    value={aircrafts.find((pil) => pil.id === newAircraft.idAircraft) || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      const selectedAircraftId = newValue ? newValue.id : null;
                      handleChange({ target: { name: 'idAircraft', value: selectedAircraftId } });
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!newAircraft?.idAircraft}
                        helperText={!newAircraft?.idAircraft ? 'Must not be Empty' : ''}
                        {...params}
                        label="Aircrafts"
                      />
                    )}
                  />
                </Grid>
              )}
              {isPolicyAircraft && (
                <>
                  <Grid item xs={isQuote ? 6 : 12}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idLiability || ''}
                      label="Aircraft Liability"
                      name="idLiability"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftLiabilities, {
                        id: newAircraft?.idLiability,
                        shortCode: newAircraft?.PolicyOptionAircraftLiability?.shortCode
                      })?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.shortCode} - ${item.description}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {isQuote && (
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Liability Amount"
                        name="idLiabilityAmount"
                        value={newAircraft?.idLiabilityAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{
                          inputProps: { min: 0, step: 0.01 },
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item xs={isQuote ? 6 : 12}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idHull || ''}
                      label="Aircraft Hull"
                      name="idHull"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftHulls, {
                        id: newAircraft?.idHull,
                        shortCode: newAircraft?.PolicyOptionAircraftHull?.shortCode
                      })?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {isQuote && (
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Hull Amount"
                        name="idHullAmount"
                        value={newAircraft?.idHullAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item xs={isQuote ? 6 : 12}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idMedical || ''}
                      label="Aircraft Medical"
                      name="idMedical"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftMedicals, {
                        id: newAircraft?.idMedical,
                        shortCode: newAircraft?.PolicyOptionAircraftMedical?.shortCode
                      }).map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.shortCode} - ${item.description}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {isQuote && (
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Medical Amount"
                        name="idMedicalAmount"
                        value={newAircraft?.idMedicalAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idUse || ''}
                      label="Aircraft Use"
                      name="idUse"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftUses, {
                        id: newAircraft?.idHull,
                        shortCode: newAircraft?.PolicyOptionAircraftHull?.shortCode,
                        description: newAircraft?.PolicyOptionAircraftUse?.description
                      })?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.shortCode} - ${item.description}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idTerritory || ''}
                      label="Aircraft Territory"
                      name="idTerritory"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftTerritories, {
                        id: newAircraft?.idTerritory,
                        shortCode: newAircraft?.PolicyOptionAircraftTerritory?.shortCode
                      }).map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idWarTria || ''}
                      label="Aircraft WarTria"
                      name="idWarTria"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftWarTrias, {
                        id: newAircraft?.idWarTria,
                        shortCode: newAircraft?.PolicyOptionAircraftWarTria?.shortCode
                      }).map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Hull NIM Deductible"
                      name="idHullNIMDeductible"
                      value={newAircraft?.idHullNIMDeductible || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Hull IM Deductible"
                      name="idHullIMDeductible"
                      value={newAircraft?.idHullIMDeductible || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Taxes and Fee's"
                      name="taxesFees"
                      value={newAircraft?.taxesFees || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Registation Number"
                  name="registrationNo"
                  variant="outlined"
                  required
                  value={newAircraft?.registrationNo || ''}
                  error={!newAircraft?.registrationNo?.trim()}
                  helperText={!newAircraft?.registrationNo?.trim() ? 'First Name cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDialogTitle>Hangered</CustomDialogTitle>

                <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="hangered" value={newAircraft?.hangered || false} onChange={handleChange}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                      </Grid>
                      <Grid item>
                        <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDialogTitle>Leased</CustomDialogTitle>

                <FormControl component="fieldset">
                  <RadioGroup aria-label="leased" name="isLeased" value={newAircraft?.isLeased || false} onChange={handleChange}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                      </Grid>
                      <Grid item>
                        <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} className="fixSelector">
                <FormControlCustom variant="standard">
                  <InputLabel id="demo-mutiple-name-label">Registered Pilots</InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id={`demo-simple-select-${!newAircraft?.pilotAircraft?.length ? 'error' : 'outlined'}`}
                    multiple
                    name="pilotAircraft"
                    value={newAircraft?.pilotAircraft || []}
                    required
                    onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {pilots.map((pilot) => (
                      <MenuItem key={pilot.id} value={pilot.id}>
                        {`${pilot.firstname} ${pilot.lastname}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {!newAircraft?.pilotAircraft?.length && (
                    <FormHelperText style={{ color: '#ff413a' }}>Pilot Certifications cannot be Empty</FormHelperText>
                  )}
                </FormControlCustom>
              </Grid> */}
              {actionType !== 'CreateWorkflow' && actionType !== 'EditWorkflow' && (
                <Grid item xs={12} className="fixSelector">
                  <Autocomplete
                    sx={{ mt: 1 }}
                    fullWidth
                    options={deactiveArrayUtil(customerOrg, {
                      id: newAircraft?.idCustomerOrganization,
                      name: newAircraft?.CustomerOrganization?.name
                    })}
                    getOptionLabel={(option) => ` ${option.id}: ${option.name}`}
                    value={
                      deactiveArrayUtil(customerOrg, {
                        id: newAircraft?.idCustomerOrganization,
                        name: newAircraft?.CustomerOrganization?.name
                      }).find((pil) => pil.id === newAircraft.idCustomerOrganization) || null
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      const selectedCustomerOrgId = newValue === undefined ? null : newValue?.id;
                      handleChange({
                        target: { name: 'idCustomerOrganization', value: selectedCustomerOrgId }
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!newAircraft?.idCustomerOrganization}
                        helperText={!newAircraft?.idCustomerOrganization ? 'Must not be Empty' : ''}
                        {...params}
                        label="Customer Organization*"
                      />
                    )}
                  />
                </Grid>
              )}
              <DialogTitle>Add Aircraft Indetification</DialogTitle>
              <Grid container spacing={2} sx={{ padding: '0 24px' }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Make"
                    name="make"
                    variant="outlined"
                    value={newAircraft.make || ''}
                    onChange={handleChange}
                    error={!newAircraft?.make?.trim()}
                    helperText={!newAircraft?.make?.trim() ? 'Invalid Make' : ''}
                    sx={{ pb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    name="seats"
                    label="Number of Seats"
                    variant="outlined"
                    value={newAircraft?.seats || ''}
                    error={!newAircraft?.seats}
                    helperText={!newAircraft?.seats ? 'Invalid Number' : ''}
                    inputProps={{ inputMode: 'numeric' }}
                    onChange={handleChange}
                    sx={{ pb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Type"
                    name="type"
                    variant="outlined"
                    value={newAircraft.type || ''}
                    onChange={handleChange}
                    error={!newAircraft?.type?.trim()}
                    helperText={!newAircraft?.type?.trim() ? 'Invalid Type' : ''}
                    sx={{ pb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    label="Value"
                    name="value"
                    variant="outlined"
                    value={newAircraft.value || ''}
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <InputAdornment position="start" sx={{ marginTop: -0.1 }}>
                          $
                        </InputAdornment>
                      )
                    }}
                    error={!newAircraft?.value}
                    helperText={!newAircraft?.value ? 'Invalid Value' : ''}
                    onChange={handleChange}
                    sx={{ pb: 2 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Year*"
                    name="year"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !dayjs(newAircraft?.year?.toString(), 'YYYY', false).isValid || !newAircraft?.year,
                        helperText: !dayjs(newAircraft?.year).isValid ? 'Invalid Year' : '' || !newAircraft?.year ? 'Cannot be Empty' : '',
                        sx: { mb: 2 }
                      }
                    }}
                    views={['year']}
                    value={newAircraft?.year ? dayjs(newAircraft?.year?.toString()) : null}
                    onChange={handleDateChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Airport Code"
                    name="airportCode"
                    variant="outlined"
                    value={newAircraft.airportCode || ''}
                    onChange={handleChange}
                    error={newAircraft?.airportCode?.trim()?.length !== 3 && newAircraft?.airportCode?.trim()?.length !== 4}
                    helperText={
                      newAircraft?.airportCode?.trim()?.length !== 3 && newAircraft?.airportCode?.trim()?.length !== 4
                        ? 'Airport Code has to be 3 or 4 characters long'
                        : ''
                    }
                    sx={{ pb: 2 }}
                  />
                </Grid>
              </Grid>
              <DialogTitle>Add Registered Address</DialogTitle>
              <Grid container spacing={2} sx={{ padding: '0 24px' }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    sx={{ mb: 1 }}
                    name="registeredOwner"
                    label="Registered Owner"
                    variant="outlined"
                    error={!newAircraft?.registeredOwner?.trim()}
                    helperText={!newAircraft?.registeredOwner?.trim() ? 'Invalid Name' : ''}
                    value={newAircraft.registeredOwner || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mb: 1 }}
                    fullWidth
                    label="Email"
                    name="registeredEmail"
                    variant="outlined"
                    input={<Input />}
                    value={newAircraft?.registeredEmail || ''}
                    error={newAircraft?.registeredEmail?.trim()?.length > 0 ? !newAircraft?.registeredEmail?.match(validRegex) : false}
                    helperText={
                      newAircraft?.registeredEmail?.trim().length > 0
                        ? !newAircraft?.registeredEmail?.match(validRegex)
                          ? 'Invalid Email'
                          : ''
                        : ''
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mb: 1 }}
                    fullWidth
                    label="Phone"
                    name="registeredPhone"
                    variant="outlined"
                    value={newAircraft.registeredPhone || ''}
                    onChange={handleChange}
                    error={
                      newAircraft?.registeredPhone?.trim()?.length > 0 ? !newAircraft?.registeredPhone?.match(phoneNumberRegex) : false
                    }
                    helperText={
                      newAircraft?.registeredPhone?.trim().length > 0
                        ? !newAircraft?.registeredPhone?.match(phoneNumberRegex)
                          ? 'Invalid Phone Number'
                          : ''
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    sx={{ mt: 1 }}
                    name="registeredAddress1"
                    label="Address 1"
                    required
                    variant="outlined"
                    error={!newAircraft?.registeredAddress1?.trim()}
                    helperText={!newAircraft?.registeredAddress1?.trim() ? 'Invalid Address' : ''}
                    value={newAircraft.registeredAddress1 || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    name="registeredAddress2"
                    label="Address 2"
                    variant="outlined"
                    value={newAircraft.registeredAddress2 || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    name="registeredCity"
                    label="City"
                    required
                    variant="outlined"
                    value={newAircraft.registeredCity || ''}
                    error={!newAircraft?.registeredCity?.trim()}
                    helperText={!newAircraft?.registeredCity?.trim() ? 'Invalid Type' : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    value={newAircraft.registeredState || ''}
                    onChange={handleChange}
                    name="registeredState"
                    label="State"
                    error={!newAircraft?.registeredState}
                    helperText={!newAircraft?.registeredState ? 'Cannot be Empty' : ''}
                    select
                  >
                    {states.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    required
                    name="registeredZip"
                    label="Zipcode"
                    variant="outlined"
                    error={!newAircraft?.registeredZip?.trim()}
                    helperText={!newAircraft?.registeredZip?.trim() ? 'Invalid Zipcode' : ''}
                    value={newAircraft.registeredZip || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        ) : (
          <DialogContentText>Confirm if you want to delete the this Aircraft</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          ref={saveInput}
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            actionType !== 'Delete'
              ? actionType !== 'Details'
                ? !newAircraft?.registrationNo ||
                  !newAircraft?.idCustomerOrganization ||
                  !newAircraft?.make ||
                  !newAircraft?.seats ||
                  !newAircraft?.type ||
                  !newAircraft?.year ||
                  !newAircraft?.value ||
                  (newAircraft?.airportCode?.trim()?.length !== 3 && newAircraft?.airportCode?.trim()?.length !== 4) ||
                  !newAircraft?.registeredOwner ||
                  !newAircraft?.registeredAddress1 ||
                  !newAircraft?.registeredCity ||
                  !newAircraft?.registeredState ||
                  !newAircraft?.registeredZip
                : !newAircraft?.idAircraft
              : false
          }
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AircraftDialog;
