import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, CardContent, TextField, Divider, Button, Tooltip, IconButton, MenuItem } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { states } from 'utils/states';

const policyColumns = [
  {
    id: 'agentDisplayName',
    label: 'Agents Display Name'
  },
  {
    id: 'policyNumber',
    label: 'Policy Number'
  },
  {
    id: 'policyStatus',
    label: 'Policy Status'
  }
];

const AgencyDetails = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);
  const { companyId, agencyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [agency, setAgency] = useState({});
  const [policies, setPolicies] = useState([]);
  const [policyPagination, setPolicyPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });

  const handleChange = (event) => {
    const { value } = event.target;
    setAgency({
      ...agency,
      name: value
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/agency/${agencyId}/companyId/${companyId}`, {
      agency: {
        name: agency?.name,
        idCompany: agency?.idCompany
      }
    });
    setAgency({ ...response.data });
    setIsLoading(false);
  };

  const handleGoToAgents = () => {
    navigate(`/company/${companyId}/agency/${agency.id}/agents`);
  };
  const handlePolicyChangeRowsPerChange = (event) => {
    setPolicyPagination({ ...policyPagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handlePolicyChangePage = async (event, page) => {
    setPolicyPagination({ ...policyPagination, page });
  };

  const handleGoToDetail = (policy) => {
    navigate(`/company/${companyId}/policy/${policy.id}`);
  };

  useEffect(() => {
    const getAgency = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/agency/${agencyId}/companyId/${companyId}`);
      setAgency({ ...response.data });
      setPolicies([...response.data.Policy.filter((item) => item.CustomerOrganization.idCompany == companyId)]);
      setPolicyPagination({
        ...policyPagination,
        count: response?.data?.Policy?.filter((item) => item.CustomerOrganization.idCompany == companyId).length
      });
      setIsLoading(false);
    };

    getAgency();
  }, [companyId]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Agency Details" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/agency`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Agencies
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Agency Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="name"
                            label="Name"
                            variant="outlined"
                            value={agency?.name || ''}
                            error={!agency?.name}
                            helperText={!agency?.name ? 'Cannot be Empty' : ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Company Name"
                            variant="outlined"
                            value={agency?.Company?.name || ''}
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Agency Code"
                            name="code"
                            variant="outlined"
                            value={agency?.code || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Address 1"
                            name="address1"
                            variant="outlined"
                            value={agency?.address1 || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Address 2"
                            name="address2"
                            variant="outlined"
                            value={agency?.address2 || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="City"
                            name="city"
                            variant="outlined"
                            value={agency?.city || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                          />
                        </Typography>
                      </Grid> <Grid item lg={4} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="State"
                            name="state"
                            select
                            variant="outlined"
                            value={agency?.state || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                          >
                            {states.map(state => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Typography>
                      </Grid> <Grid item lg={4} xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Zipcode"
                            name="zip"
                            variant="outlined"
                            value={agency?.zip || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                      <Grid item sm={6} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          disabled={!agency?.name || !auth.writePrivledge(auth.user, 'Agency_Adm')}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                      <Grid item sm={6} md={4}>
                        <Button variant="contained" color="primary" onClick={handleGoToAgents}>
                          Go to Agents
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <CustomTable
                  columns={policyColumns}
                  data={policies.slice(
                    policyPagination?.page * policyPagination?.rowsPerPage,
                    (policyPagination?.page + 1) * policyPagination?.rowsPerPage
                  )}
                  isLoading={isLoading}
                  hasPagination={true}
                  pagination={policyPagination}
                  handleChangePage={handlePolicyChangePage}
                  handleChangeRowsPerChange={handlePolicyChangeRowsPerChange}
                  title="Policy"
                >
                  {(row) => (
                    <>
                      <Tooltip title="Policy Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                          <TextSnippetIcon fontSize='large'/>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AgencyDetails;
