import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
  header: {
    fontSize: 10,
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  contentDivider: {
    borderTop: '1px solid black'
  },
  centeredText: {
    textAlign: 'center'
  },
  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 5
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  content: {
    fontSize: 8,
    gap: 5
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },

  nestedListContainer: {
    paddingLeft: 15,
    gap: '-6px' // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid black',
    marginTop: 10,
    marginLeft: 15
  },
  row: {
    flexDirection: 'row',
    borderBottom: '1px solid black',
    alignItems: 'center',
    padding: 2
  },
  noCenterRow: {
    flexDirection: 'row',
    borderBottom: '1px solid black'
  },
  headerRow: {
    fontWeight: 'bold',
    backgroundColor: '#eee'
  },
  cell: {
    fontSize: 6,
    padding: 1,
    textAlign: 'center'
  },
  noCenterCell: {
    fontSize: 6,
    padding: 1
  },
  column: {
    flex: 1,
    borderRight: '1px solid black'
  },
  noBorderRight: {
    borderRight: 'none'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 7,
    width: '100%'
  },
  firstColumn: {
    width: '30%',
    borderRight: '1px solid black'
  },
  secondColumn: {
    width: '70%'
  },
  columnLarge: {
    flex: 2, // Takes up two-thirds of the space
    textAlign: 'center',
    padding: 4
  },
  columnSmall: {
    flex: 1, // Each takes up one-third of the remaining space
    textAlign: 'center',
    padding: 4
  },

  columnLargeNoCenter: {
    flex: 4, // Takes up two-thirds of the space
    padding: 4
  },
  spaceBetweenRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  spaceBetweenRowNoCenter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  leftColumn: {
    flex: 1,
  },
  rightColumn: {
    width: '25%',
    alignItems: 'flex-end',
  },
  boxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid black',
    height: 20,
    marginBottom: 8,
  },
  box: {
    flex: 1,
    borderLeft: '1px solid black',
    height: '100%',
  },
  textRow: {
    flexDirection: 'row',
    marginBottom: 4,
  },
  footerSubContainer: {
    fontFamily: 'OpenSans',
    fontSize: 6,
    position: 'absolute',
    width: '100%',
    marginLeft: '50px',
    bottom: 20
  },
  hiddenText: {
    color: 'transparent'
  }
});
