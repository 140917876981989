import { Document, Page, Text, View, Font, StyleSheet } from '@react-pdf/renderer';
import { styles } from '../PolicyPDF/Styles';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { calculateTotal } from 'utils/MathFunctions';
import { formatDate } from 'utils/FormatUtils';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const aircraftStyles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1 solid black',
    paddingBottom: 5,
    marginBottom: 5
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  leftColumn: {
    flex: 2
  },
  rightColumn: {
    flex: 1,
    textAlign: 'right'
  },
  bold: {
    fontWeight: 'bold'
  },
  additionalInsuredSection: {
    marginTop: 10,
    borderTop: '1 solid black',
    paddingTop: 5
  },
  additionalInsuredTitle: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 5
  },
  totalLabel: {
    fontWeight: 'bold',
    marginRight: 20
  },
  totalAmount: {
    textAlign: 'right'
  },
  amountSpacing: {
    marginLeft: 30
  }
});

const Header = ({ title }) => (
  <View style={{ position: 'relative', padding: '10px 0', fontSize: '16px', textAlign: 'center', borderBottom: '1px sold blue' }}>
    <View
      style={{
        position: 'absolute',
        opacity: 0.2,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'gray'
      }}
    />
    <Text style={[styles.boldText, { color: 'blue' }]}>{title}</Text>
  </View>
);

const Aircraft = ({ aircraft }) => (
  <>
    <View style={aircraftStyles.header}>
      <Text style={[styles.boldText, { color: 'blue', fontSize: '16px' }]}>{aircraft?.Aircraft?.registrationNo}</Text>
      <Text>{`${aircraft?.Aircraft?.year} ${aircraft?.Aircraft?.make}`}</Text>
      <Text>SEATS: {aircraft?.Aircraft?.seats}</Text>
      <Text style={styles.boldText}>Premium</Text>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>AIRCRAFT LIABILITY:</Text>
        <Text>{aircraft?.idLiability ? aircraft?.PolicyOptionAircraftLiability?.description : `Not Included`}</Text>
      </View>
      <Text style={aircraftStyles.rightColumn}>{aircraft?.idLiabilityAmount ? `$ ${aircraft?.idLiabilityAmount}` : ''}</Text>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>PHYSICAL DAMAGE:</Text>
        <Text>{aircraft?.idHull ? aircraft?.PolicyOptionAircraftHull?.description : 'Not Included'}</Text>
      </View>
      <Text style={aircraftStyles.rightColumn}>{aircraft?.idHullAmount ? `$ ${aircraft?.idHullAmount}` : ''}</Text>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>DEDUCTIBLES:</Text>
        <Text>
          NOT-IN-MOTION: {aircraft?.idHullNIMDeductible ? `$ ${aircraft?.idHullNIMDeductible}` : ''} IN-MOTION:{' '}
          {aircraft?.idHullIMDeductible ? `$ ${aircraft?.idHullIMDeductible}` : ''}
        </Text>
      </View>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>MEDICAL PAYMENTS:</Text>
        <Text>{aircraft?.idMedical ? aircraft?.PolicyOptionAircraftMedical?.description : ''}</Text>
      </View>
      <Text style={aircraftStyles.rightColumn}>{aircraft?.idMedicalAmount ? `$ ${aircraft?.idMedicalAmount}` : ''}</Text>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>COVERED TERRITORY:</Text>
        <Text>THE CONTIGUOUS UNITED STATES, MEXICO, CANADA, AND THE BAHAMA ISLANDS</Text>
      </View>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>COVERED USE:</Text>
        <Text>{aircraft?.idUse ? aircraft?.PolicyOptionAircraftUse?.description : 'Not Included'}</Text>
      </View>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>WAR / TRIA COVERAGE:</Text>
        <Text>NOT INCLUDED</Text>
      </View>
    </View>
    <View style={aircraftStyles.totalRow}>
      <Text style={[styles.totalLabel, styles.boldText]}>STATE TAXES/FEES:</Text>
      <Text style={[aircraftStyles.totalAmount, aircraftStyles.amountSpacing]}></Text>
    </View>
    <View style={aircraftStyles.totalRow}>
      <Text style={[aircraftStyles.totalLabel, styles.boldText]}>TOTAL:</Text>
      <Text style={aircraftStyles.totalAmount}>$ {calculateTotal(aircraft)}</Text>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>LIENHOLDER:</Text>
        <Text>NO LIEN</Text>
      </View>
    </View>
    <View style={aircraftStyles.section}>
      <View style={aircraftStyles.leftColumn}>
        <Text style={styles.boldText}>BASE:</Text>
        <Text>{aircraft?.Aircraft?.isHangered && `Hangered at ${aircraft?.Aircraft?.airportName}`}</Text>
      </View>
    </View>
  </>
);

const QuotePDF = ({ policy, aircraft, pilots }) => {
  if (policy && aircraft && pilots?.length > 0) {
    const pilot = pilots[0];
    return (
      <Document>
        <Page size="Letter">
          {/* Header */}
          <View style={[styles.header, { textAlign: 'center', fontSize: '20px', padding: '20px' }]}>
            <Text style={{ color: 'blue' }}>AIRCRAFT INSURANCE QUOTE</Text>
          </View>
          <View style={[styles.content, { fontSize: '10px' }]}>
            <Header title={policy?.agencyName} />
            <View style={{ padding: '5px 20px' }}>
              <View style={{ gap: 10 }}>
                <Text>Named Insured: {policy?.clientNameInsured}</Text>
                <Text>
                  Address:{' '}
                  {`${policy?.customerOrganizationAddress1}, ${policy?.customerOrganizationCity}, ${policy?.customerOrganizationState}, ${policy?.customerOrganizationZip} `}
                </Text>
                <Text>Business: {policy?.Options_Policy_customerOrganizationOrgTypeToOptions?.val}</Text>
                <Text>Coverage Effective: JANUARY 24, 2026 TO JANUARY 24, 2027</Text>
              </View>
            </View>
            <Header title="Aircraft" />
            <View style={{ padding: 10 }}>
              <Aircraft aircraft={aircraft} />
            </View>
            <Header title="Pilots" />
            <View style={{ padding: 10 }}>
              <View style={[styles.header]}>
                <Text style={[styles.boldText, { color: 'blue', fontSize: '16px' }]}>{`${pilot?.PilotDetails?.lastname}, ${pilot?.PilotDetails?.lastname}`}</Text>
              </View>
              <View style={{ gap: 20, marginTop: 1 }}>
                <View style={{ display: 'flex', flexDirection: 'row', gap: 100, padding: '0 50px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Certificate:</Text>
                    <Text style={aircraftStyles.amountSpacing}>
                      {pilot?.PilotDetails?.PilotDetailsCerts?.map((item) => item?.Options?.val).join(', ') || ''}
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Ratings:</Text>
                    <Text style={aircraftStyles.amountSpacing}> {pilot?.PilotDetails?.PilotDetailsRating?.map((item) => item?.Options?.val).join(', ') || ''}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>FAA Certificate No.</Text>
                    <Text style={aircraftStyles.amountSpacing}></Text>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', gap: 100, padding: '0 50px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Date of Birth:</Text>
                    <Text style={aircraftStyles.amountSpacing}>{formatDate(pilot?.PilotDetails?.dateofbirth).format('MM/DD/YYYY')}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Email:</Text>
                    <Text style={aircraftStyles.amountSpacing}>{pilot?.PilotDetails?.email}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Mobile Phone:</Text>
                    <Text style={aircraftStyles.amountSpacing}>{pilot?.PilotDetails?.phone}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
        <Page size="LETTER">
          <View style={[styles.content, { fontSize: 10, marginTop: 5 }]}>
            <View style={{ padding: '20px 20px' }}>
              <View style={[styles.header, { textAlign: 'center', fontSize: 14 }]}>
                <Text style={{ textDecoration: 'underline' }}>Logged Flight Hours</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 50, padding: '0 50px' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={[styles.boldText, { textDecoration: 'underline' }]}>TOTAL LOGGED HOURS:</Text>
                  <Text style={[aircraftStyles.amountSpacing, { marginTop: 2 }]}>2200</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={[styles.boldText, { textDecoration: 'underline' }]}>MULTI-ENGINE:</Text>
                  <Text style={[aircraftStyles.amountSpacing, { marginTop: 2 }]}>{pilot?.PilotDetails?.multiEngine}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={[styles.boldText, { textDecoration: 'underline' }]}>MAKE & MODEL </Text>
                  <Text style={[aircraftStyles.amountSpacing, { marginTop: 2 }]}>{pilot?.PilotDetails?.makeAndModel}</Text>
                </View>
              </View>
            </View>
            <Header title="Thomas Johnson" />
            <View style={{ padding: '5px 20px' }}>
              <View style={{ gap: 10 }}>
                <Text style={{ textAlign: 'center' }}>Aircraft Policy Effective: JANUARY 24, 2026 TO JANUARY 24, 2027</Text>
              </View>
            </View>
            <Header title="Pilots" />
            <View style={{ padding: 10 }}>
              <View style={[styles.header]}>
                <Text style={[styles.boldText, { color: 'blue', fontSize: '16px' }]}>Open Pilot Clause</Text>
              </View>
              <View style={{ gap: 20, marginTop: 1 }}>
                <View style={{ display: 'flex', flexDirection: 'row', gap: 100, padding: '0 50px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Certificate:</Text>
                    <Text style={aircraftStyles.amountSpacing}>PVT</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Ratings:</Text>
                    <Text style={aircraftStyles.amountSpacing}>AMEL`; INST</Text>
                  </View>
                </View>
                <View style={[styles.header, { textAlign: 'center', fontSize: 14 }]}>
                  <Text style={{ textDecoration: 'underline' }}>Logged Flight Hours</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', gap: 50, padding: '0 50px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[styles.boldText, { textDecoration: 'underline' }]}>TOTAL LOGGED HOURS:</Text>
                    <Text style={[aircraftStyles.amountSpacing, { marginTop: 2 }]}>2200</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[styles.boldText, { textDecoration: 'underline' }]}>MULTI-ENGINE:</Text>
                    <Text style={[aircraftStyles.amountSpacing, { marginTop: 2 }]}>300</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[styles.boldText, { textDecoration: 'underline' }]}>MAKE & MODEL </Text>
                    <Text style={[aircraftStyles.amountSpacing, { marginTop: 2 }]}>200</Text>
                  </View>
                </View>
                <View style={[styles.header, { textAlign: 'center', fontSize: 14 }]}>
                  <Text style={{ textDecoration: 'underline' }}>ADDITIONAL PILOT REQUIREMENTS</Text>
                </View>
                <View style={{ marginLeft: 15 }}>
                  <Text>
                    ALL PILOTS MUST HAVE COMPLETRED AN INITIAL GROUND AND FLIGHT TRAINING COURSE FOR BEECHA BE58P AIRACT AND THEN COMPLETE
                    AN IPC WITHIN THE 12 MONTHS PRECEDING THE DATEW OF ANY FLIGHT.
                  </Text>
                </View>
              </View>
            </View>
            <Header title="Premium" />
            <View style={{ padding: '5px 20px' }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Text style={[styles.boldText, { textAlign: 'center' }]}>TOTAL ANNUAL PREMIUM:</Text>
                <Text>$ {Object.values(aircraft)?.length > 0 ? calculateTotal(aircraft) : '0'}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  } else {
    return (
      <Document>
        <Page>
          <View>
            <Text>Something went wrong when creating the pdf</Text>
          </View>
        </Page>
      </Document>
    );
  }
};

export default QuotePDF;
