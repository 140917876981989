import {
  Tooltip,
  IconButton,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  DialogContentText,
  Button,
} from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import CustomTable from 'component/CustomTable';

import initializeAxios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { gridSpacing } from 'config.js';
import { Transition } from 'utils/transition';
import React, { useEffect, useState } from 'react';

const columns = [
  {
    id: 'description',
    label: 'Description'
  },
  {
    id: 'hours',
    label: 'Hours'
  }
];


const PilotOptions = ({ isLoading, data, pilotId, updatePilotOption, companyId, totalHours }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [pilotOption, setPilotOption] = useState({});
  const [pilotOptions, setPilotOptions] = useState([]);
  const [actionType, setActionType] = useState('');
  const [pilotOptionId, setPilotOptionId] = useState(null);
  const [recordedTotalHours, setRecordedTotalHours] = useState(0);
  const [tempTotalHours, setTempTotalHours] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
    setTempTotalHours(recordedTotalHours);
    setActionType('Create');
  };

  const handleEdit = (editOption) => {
    setPilotOption({ ...editOption });
    setPilotOptionId(editOption.id);
    const oldHours = +editOption?.hours;
    setTempTotalHours(+recordedTotalHours - oldHours)
    setActionType('Edit');
    setOpen(true);
  };

  const handleDelete = (deleteOption) => {
    setPilotOptionId(deleteOption.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setPilotOption({});
  };

  const handleSave = async () => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/pilotOption/companyId/${companyId}`, {
          pilotOption: {
            description: pilotOption?.description,
            hours: +pilotOption?.hours,
            idPilot: +pilotId
          }
        });
        setPilotOptions([...pilotOptions, response.data]);
        updatePilotOption(response.data, 'Create');
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/pilotOption/${pilotOptionId}/companyId/${companyId}`, {
          pilotOption: {
            description: pilotOption?.description,
            hours: +pilotOption?.hours
          }
        });
        const tempArray = [...pilotOptions];
        const index = tempArray.findIndex((e) => e.id === pilotOptionId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setPilotOptions(tempArray);
        updatePilotOption(tempArray, 'Edit');
        break;
      }
      case 'Delete': {
        await axios.delete(`/api/pilotOption/${pilotOptionId}/companyId/${companyId}`);
        const tempArray = [...pilotOptions];
        const index = tempArray.findIndex((e) => e.id === pilotOptionId);
        tempArray.splice(index, 1);
        setPilotOptions(tempArray);
        updatePilotOption(tempArray, 'Delete');
        break;
      }
    }
    handleClosedialog();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPilotOption({
      ...pilotOption,
      [name]: value
    });
  };

  useEffect(() => {
    setPilotOptions(data);
    setRecordedTotalHours(data.reduce((total, next) => total + (next?.hours ? +next?.hours : 0), 0));
  }, [data]);

  return (
    <>
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        data={pilotOptions}
        hasPagination={false}
        title="Pilot Hours"
        hasCreateButton={true}
        isDisabled={!auth.writePrivledge(auth.user, 'Pilot_Adm')}
        handleOpenCreate={handleClickOpenDialog}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Pilot Hour" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Pilot_Adm')}
                  onClick={() => handleEdit(pilotOptions[index])}
                >
                  <EditTwoToneIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Pilot Hour" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Pilot_Adm')}
                  onClick={() => handleDelete(pilotOptions[index])}
                >
                  <DeleteIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosedialog}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              maxHeight: '100%',
              height: '100vh'
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create'
            ? 'New Pilot Hour'
            : actionType === 'Edit'
            ? 'Edit Pilot Hour'
            : actionType === 'Delete'
            ? 'Delete Pilot Hour'
            : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="description"
                  name="description"
                  variant="outlined"
                  value={pilotOption?.description || ''}
                  error={!pilotOption?.description?.trim()}
                  helperText={!pilotOption?.description?.trim() ? 'Description cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Hours"
                  id="filled-start-adornment1"
                  name="hours"
                  value={pilotOption?.hours || ''}
                  type="number"
                  onChange={handleChange}
                  variant="outlined"
                  error={pilotOption?.hours <= 0 || !pilotOption?.hours || +pilotOption?.hours + +tempTotalHours > totalHours}
                  helperText={
                    ((pilotOption?.hours <= 0 || !pilotOption?.hours) && 'Hours Cannot Be Empty') ||
                    (+pilotOption?.hours + +tempTotalHours > totalHours && 'Cannot Exceed total hours')
                  }
                />
              </Grid>
            </Grid>
          ) : (
            <DialogContentText>Confirm if you want to delete the this Pilot Hour</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              actionType !== 'Delete'
                ? pilotOption?.hours <= 0 ||
                  !pilotOption?.hours ||
                  !pilotOption?.description?.trim() ||
                  +pilotOption?.hours + +tempTotalHours > totalHours
                : false
            }
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClosedialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PilotOptions;
