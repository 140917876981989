import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';

// project import
import Breadcrumb from 'component/Breadcrumb';
import initializeAxios from 'utils/axios';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import UserIcon from '@mui/icons-material/Person';
import '../../../assets/scss/actions.scss';
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import CompanyDialog from 'component/CompanyDialog';

// ==============================|| CONTACT LIST ||============================== //
const columns = [
  {
    id: 'id',
    label: 'Id'
  },
  { id: 'name', label: 'Company Name' }
];

const Company = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleSave = async (dialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post('/api/company', {
          company: {
            ...dialog
          }
        });
        setCompanies([...companies, response.data]);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/company/${companyId}`, {
          company: {
            ...dialog
          }
        });
        const tempArray = [...companies];
        const index = tempArray.findIndex((e) => e.id === companyId);
        tempArray[index] = {
          ...response.data
        };
        setCompanies(tempArray);
        break;
      }

      case 'Delete': {
        await axios.delete(`/api/company/${companyId}`);
        const tempArray = [...companies];
        const index = tempArray.findIndex((e) => e.id === companyId);
        tempArray.splice(index, 1);
        setCompanies(tempArray);
        break;
      }
    }

    setOpen(false);
  };

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [actionType, setActionType] = useState('');
  const navigate = useNavigate();

  const handleEdit = (editCompany) => {
    setCompany({
      name: editCompany?.name,
      address1: editCompany?.address1,
      address2: editCompany?.address2,
      city: editCompany?.city,
      state: editCompany?.state,
      zip: editCompany?.zip
    });
    setCompanyId(editCompany.id);
    setActionType('Edit');
    setOpen(true);
  };

  const handleDelete = (deleteItem) => {
    setCompanyId(deleteItem.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleUser = (row) => {
    navigate(`/admin/company/${row.id}/users`);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get('/api/company')
      .then((response) => {
        setCompanies(response.data);
      })
      .then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Breadcrumb title="List of Companies" sx={{ mt: { xs: 2, lg: 0 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Companies {'( Admin )'}
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <CompanyDialog open={open} company={company} handleSave={handleSave} handleClose={handleClosedialog} actionType={actionType} />
      <CustomTable
        title="Companies"
        data={companies}
        columns={columns}
        isLoading={isLoading}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Tooltip title="Edit Company">
              <span>
                <IconButton color="primary" aria-label="Edit" size="large" disabled={row.id === 1} onClick={() => handleEdit(row)}>
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Company">
              <span>
                <IconButton color="secondary" aria-label="Edit" size="large" disabled={row.id === 1} onClick={() => handleDelete(row)}>
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Company Users">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleUser(row)}>
                <UserIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Company;
