import { Grid, Dialog, DialogContent, DialogTitle, TextField, DialogContentText, DialogActions, Button } from '@mui/material';
import { gridSpacing } from 'config.js';
import { useEffect, useState } from 'react';
import { Transition } from 'utils/transition';

const InsuranceDialog = ({ open, newInsurance, handleSave, handleClose, actionType }) => {
  const [insurance, setInsurance] = useState({});

  const handleInsuranceSave = () => {
    handleSave(insurance);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInsurance({
      ...insurance,
      [event.target.name]: value
    });
  };

  const handleClosedialog = () => {
    setInsurance({});
    handleClose();
  };

  useEffect(() => {
    if (Object.values(newInsurance)?.length > 0) {
      const tempInsurance = { ...newInsurance };
      setInsurance(tempInsurance);
    }
  }, [newInsurance]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClosedialog}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh',
            width: { xs: '100vw' }
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create'
          ? 'New Insurance'
          : actionType === 'Edit'
          ? 'Edit Insurance'
          : actionType === 'Delete'
          ? 'Delete Insurance'
          : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="name"
                label="Insurance Name"
                variant="outlined"
                onChange={handleChange}
                value={insurance.name || ''}
                error={!insurance?.name}
                helperText={!insurance?.name ? 'Cannot Be Empty' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="code"
                label="Insurance Code"
                variant="outlined"
                value={insurance.code || ''}
                error={!insurance?.code}
                helperText={!insurance?.code ? 'Cannot Be Empty' : ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete this Insurance</DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="actions">
        <Button
          variant="contained"
          color="primary"
          onClick={handleInsuranceSave}
          disabled={actionType !== 'Delete' ? !insurance?.name || !insurance?.code : false}
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClosedialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsuranceDialog;
