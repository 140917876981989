import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';

// project import
import Breadcrumb from 'component/Breadcrumb';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LoadingOverlay from 'component/LoadingOverlay';
import InsuranceDialog from 'component/InsuranceDialog';

// ==============================|| CONTACT LIST ||============================== //
const Insurance = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'companyName',
      label: 'Company Name'
    },
    {
      id: 'name',
      label: 'Insurance Name'
    },
    {
      id: 'code',
      label: 'Code'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState('');
  const [insurances, setInsurances] = useState([]);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insurance, setInsurance] = useState({});
  const { companyId } = useParams();

  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setInsurance({});
  };

  const handleSave = async (dialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/insurance/companyId/${companyId}`, {
          insurance: {
            ...dialog,
            idCompany: +companyId
          }
        });
        setInsurances([...insurances, response.data]);
        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/insurance/${insuranceId}/companyId/${companyId}`, {
          insurance: {
            ...dialog
          }
        });
        const tempArray = [...insurances];
        const index = tempArray.findIndex((e) => e.id === insuranceId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setInsurances(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/insurance/${insuranceId}/companyId/${companyId}`);
        const tempArray = [...insurances];
        const index = tempArray.findIndex((e) => e.id === insuranceId);
        tempArray.splice(index, 1);
        setInsurances(tempArray);
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editInsurance) => {
    const propInsurance = {
      name: editInsurance.name,
      code: editInsurance.code
    };
    setInsurance(propInsurance);
    setActionType('Edit');
    setInsuranceId(editInsurance.id);
    setOpen(true);
  };

  const handleDelete = (deleteInsurance) => {
    setInsuranceId(deleteInsurance.id);
    setActionType('Delete');
    setOpen(true);
  };

  useEffect(() => {
    const getInsurances = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/insurance/companyId/${companyId}`);
      setInsurances([...result.data]);
      setIsLoading(false);
    };

    getInsurances();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Insurance" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Insurance
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <InsuranceDialog
        actionType={actionType}
        handleClose={handleClosedialog}
        handleSave={handleSave}
        newInsurance={insurance}
        open={open}
      />
      <CustomTable
        title="Insurance"
        isLoading={isLoading}
        data={insurances}
        columns={columns}
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        isDisabled={!auth.writePrivledge(auth.user, 'Insurance_Adm')}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Insurance" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Insurance_Adm')}
                  onClick={() => handleEdit(insurances[index])}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Insurance" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Insurance_Adm')}
                  onClick={() => handleDelete(insurances[index])}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Insurance;
