import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogContentText, DialogActions, Button, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { states } from 'utils/states';
import { gridSpacing } from 'config.js';
import { Transition } from 'utils/transition';

const AgencyDialog = ({ actionType, open, handleClosedialog, handleAgencySave, agency }) => {
  const [newAgency, setNewAgency] = useState({});

  const handleChange = (event) => {
    const { value, name } = event.target;

    setNewAgency({
      ...newAgency,
      [name]: value
    });
  };

  const handleClose = () => {
    setNewAgency({});
    handleClosedialog();
  };

  const handleSave = () => {
    handleAgencySave(newAgency);
  };
  const saveInput = React.createRef();

  useEffect(() => {
    if (Object.values(agency).length > 0) {
      const propAgency = { ...agency };
      setNewAgency(propAgency);
    }
  }, [agency]);
  return (
    <Dialog
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh',
            width: { xs: '100vw' }
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' ? 'New Agency' : actionType === 'Edit' ? 'Edit Agency' : actionType === 'Delete' ? 'Delete Agency' : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="name"
                label="Agency Name"
                variant="outlined"
                value={newAgency.name || ''}
                error={!newAgency?.name}
                onChange={handleChange}
                helperText={!newAgency?.name ? 'Cannot Be Empty' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="code"
                label="Agency Code"
                variant="outlined"
                value={newAgency?.code || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label="Address 1"
                name="address1"
                variant="outlined"
                value={newAgency.address1 || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label="Address 2"
                name="address2"
                variant="outlined"
                value={newAgency.address2 || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label="City"
                name="city"
                variant="outlined"
                value={newAgency.city || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                select
                label="State"
                name="state"
                variant="outlined"
                value={newAgency.state || ''}
                onChange={handleChange}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label="Zip"
                name="zip"
                variant="outlined"
                value={newAgency.zip || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete this agency</DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="actions">
        <Button
          ref={saveInput}
          variant="contained"
          color="primary"
          disabled={actionType !== 'Delete' ? !newAgency?.name : false}
          onClick={handleSave}
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgencyDialog;
