import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15,
    marginTop: '-17px'
  },

  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const SpecialEndorsement = ({ title, formNumber }) => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>{`Special Use Endorsement - ${title}`}</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This endorsement applies only to the following <Text style={styles.boldText}>aircraft:</Text>
        </Text>
      </View>
      <View style={{ textAlign: 'center' }}>
        <Text style={{ fontStyle: 'italic' }}>
          If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
          <Text style={styles.boldText}>your</Text> policy.
        </Text>
      </View>
      <View>
        <Text>
          Various provisions of this endorsement extend or restrict coverage. Read <Text style={styles.boldText}>your</Text> entire policy
          to determine <Text style={styles.boldText}>your</Text> rights and obligations and what is and is not covered. This endorsement
          changes the provisions of <Text style={styles.boldText}>your</Text> policy under the following coverage parts:
        </Text>
      </View>
      <View style={{ textAlign: 'center' }}>
        <Text style={styles.boldText}>Part Two. AIRCRAFT PHYSICAL DAMAGE COVERAGE</Text>
        <Text style={styles.boldText}>Part Three. LIABILITY TO OTHERS</Text>
      </View>
      {
        {
          'Sport Parachuting': (
            <>
              <View>
                <Text>
                  This endorsement changes <Text style={styles.boldText}>your</Text> policy and Item 10. THE USE OF THE AIRCRAFT of{' '}
                  <Text style={styles.boldText}>your</Text> Coverage Identification Page to provide coverage when the{' '}
                  <Text style={styles.boldText}>aircraft</Text> is being used for the purpose of sport parachuting involving the
                  transporting of sport parachutists in the <Text style={styles.boldText}>aircraft</Text> for which{' '}
                  <Text style={styles.boldText}>you</Text> make a charge to others.
                </Text>
              </View>
              <View>
                <Text>
                  When the <Text style={styles.boldText}>aircraft</Text> is operated for the purpose of sport parachuting:
                </Text>
              </View>
              <View style={styles.listContainer}>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      Only those pilots meeting the requirements set forth in Item 9 of the Coverage Identification Page, REQUIREMENTS FOR
                      THE PILOT FLYING THE AIRCRAFT, or an endorsement to the policy, are approved to operate the{' '}
                      <Text style={styles.boldText}>aircraft.</Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      The limitations of Paragraph 4c of Part One GENERAL PROVISIONS AND CONDITIONS do not apply when the{' '}
                      <Text style={styles.boldText}>aircraft</Text> is being used for sport parachuting provided{' '}
                      <Text style={styles.boldText}>you</Text> have a current waiver or permit from the{' '}
                      <Text style={styles.boldText}>FAA.</Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>3. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      Under Part Three, LIABILITY TO OTHERS, <Text style={styles.boldText}>we</Text> do not provide any coverage for:
                    </Text>
                  </View>
                  <View style={styles.nestedListContainer}>
                    <View>
                      <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>
                          <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> caused
                          by anyone who is jumping, attempting to jump or has jumped from the
                          <Text style={styles.boldText}>aircraft;</Text> or
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>
                          <Text style={styles.boldText}>Bodily injury</Text> to any person who is or was a{' '}
                          <Text style={styles.boldText}>passenger</Text> in the <Text style={styles.boldText}>aircraft.</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          ),
          'Aerial Advertising I': (
            <>
              <View>
                <Text>
                  This endorsement changes Item 10 of the Coverage Identification Page, THE USE OF THE AIRCRAFT, to include{' '}
                  <Text style={styles.boldText}>your</Text> use of the
                  <Text style={styles.boldText}>aircraft</Text> for aerial advertising purposes. The term “aerial advertising” means use of
                  the <Text style={styles.boldText}>aircraft</Text> for the purpose of or in conjunction with the aerial towing of
                  advertising banners and/or signs (“advertising apparatus”) for which <Text style={styles.boldText}>you</Text> make a
                  charge to others for such use. It also includes use of the <Text style={styles.boldText}>aircraft</Text> for the training
                  of pilots for aerial advertising operations regardless of whether or not a charge is made for such use.
                </Text>
              </View>
              <View>
                <Text>
                  When the <Text style={styles.boldText}>aircraft</Text> is operated for aerial advertising purposes:
                </Text>
              </View>
              <View style={styles.listContainer}>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      Only those pilots meeting the requirements set forth in Item 9 of the Coverage Identification Page, REQUIREMENTS FOR
                      THE PILOT FLYING THE AIRCRAFT, or an endorsement to the policy, are approved to operate the{' '}
                      <Text style={styles.boldText}>aircraft.</Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      In addition to WHAT IS NOT COVERED under Part Three, LIABILITY TO OTHERS, of <Text style={styles.boldText}>your</Text>{' '}
                      policy, <Text style={styles.boldText}>we</Text> also do not cover:
                    </Text>
                  </View>
                  <View style={styles.nestedListContainer}>
                    <View>
                      <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>
                          <Text style={styles.boldText}>Bodily injury</Text> to <Text style={styles.boldText}>passengers;</Text> or
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>
                          <Text style={styles.boldText}>Bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> arising
                          out of any consequential loss caused by impact of either the <Text style={styles.boldText}>aircraft</Text> or the
                          advertising apparatus with transmission lines.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>3. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      The following provisions of this endorsement will also apply to <Text style={styles.boldText}>your</Text> policy:
                    </Text>
                  </View>
                  <View style={styles.nestedListContainer}>
                    <View>
                      <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>
                          <Text style={styles.boldText}>We</Text> will not pay for physical loss of or damage to the advertising apparatus
                          either under Part Two, AIRCRAFT PHYSICAL DAMAGE COVERAGE or Part Three, LIABILITY TO OTHERS.
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>
                          <Text style={styles.boldText}>You</Text> will pay the <Text style={styles.boldText}>property damage</Text>{' '}
                          deductible shown below with respect to each <Text style={styles.boldText}>property damage</Text> claim arising out
                          of an <Text style={styles.boldText}>occurrence</Text> while the <Text style={styles.boldText}>aircraft</Text> is
                          being used for aerial advertising. <Text style={styles.boldText}>We</Text> may pay any part or all of this amount
                          to effect settlement of a claim or suit and, upon notification of the action taken, you agree to reimburse us
                          promptly for any part of this amount which was paid by <Text style={styles.boldText}>us.</Text>
                        </Text>
                      </View>
                      <View style={[styles.nestedListItemParagraph, { marginTop: '11px' }]}>
                        <Text>Property Damage Deductible: ___</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          ),
          // eslint-disable-next-line prettier/prettier
          "Sightseeing": (
            <>
              <View style={styles.listContainer}>
                <View style={styles.listItem}>
                  <Text>1. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      This endorsement changes Item 10 of the Coverage Identification Page of <Text style={styles.boldText}>your</Text>{' '}
                      policy and <Text style={styles.boldText}>your</Text> coverage to include use of the{' '}
                      <Text style={styles.boldText}>aircraft</Text> for the transportation of{' '}
                      <Text style={styles.boldText}>passengers</Text> for sightseeing purposes. <Text style={styles.boldText}>You</Text> may
                      charge others for this <Text style={styles.boldText}>use,</Text>
                      provided:
                    </Text>
                  </View>
                  <View style={styles.nestedListContainer}>
                    <View>
                      <Text style={styles.listItemHeading}>A. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>all flights are nonstop;</Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>B. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>all flights begin and end at the same airport;</Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>C. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>all flights are conducted during VFR conditions; and</Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>D. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={styles.nestedListItemParagraph}>
                        <Text>all flights are conducted within a 25 mile radius of the departure airport.</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.listItemParagraph, { marginTop: '5px' }]}>
                    <Text>
                      Unless otherwise noted below, all sightseeing flights must be conducted between the hours of sunrise and sunset, local
                      time.
                    </Text>
                  </View>
                  <View style={[styles.listItemParagraph, { marginTop: '5px' }]}>
                    <Text>
                      Exceptions: Sightseeing flights conducted between the hours of sunset and sunrise, local time are permitted by{' '}
                      <Text style={styles.boldText}>your</Text>
                      policy.
                    </Text>
                  </View>
                  <View style={[styles.listItemParagraph, { marginTop: '5px' }]}>
                    <Text>
                      As used in this endorsement, the term “airport” means a public or private use airport or heliport established and
                      maintained for the purpose of <Text style={styles.boldText}>aircraft</Text> takeoff and landing operations.
                    </Text>
                  </View>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      When the <Text style={styles.boldText}>aircraft</Text> is operated for the use described above, only those pilots
                      meeting the requirements set forth in Item 9 of the Coverage Identification Page, REQUIREMENTS FOR THE PILOT FLYING
                      THE AIRCRAFT, or an endorsement to the policy, are approved to operate the{' '}
                      <Text style={styles.boldText}>aircraft.</Text>
                    </Text>
                  </View>
                </View>
              </View>
            </>
          ),
          'Aerial Transmission or Pipeline Patrol': (
            <>
              <View>
                <Text>
                  This endorsement changes Item 10 of the Coverage Identification Page, THE USE OF THE AIRCRAFT, to include{' '}
                  <Text style={styles.boldText}>your</Text> use of the
                  <Text style={styles.boldText}>aircraft</Text> for aerial patrol of transmission lines or pipelines for which{' '}
                  <Text style={styles.boldText}>you</Text> make a charge to others for such use.
                </Text>
              </View>
              <View>
                <Text>
                  When the <Text style={styles.boldText}>aircraft</Text> is operated for aerial patrol of transmission lines or pipeline
                  purposes:
                </Text>
              </View>
              <View style={styles.listContainer}>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      Only those pilots meeting the requirements set forth in Item 9 of the Coverage Identification Page, REQUIREMENTS FOR
                      THE PILOT FLYING THE AIRCRAFT, or an endorsement to the policy, are approved to operate the aircraft.
                    </Text>
                  </View>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      Under Part Three, LIABILITY TO OTHERS, <Text style={styles.boldText}>we</Text> do not provide any coverage for:
                    </Text>
                  </View>
                  <View style={styles.nestedListContainer}>
                    <View>
                      <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.listItemParagraph, { marginTop: '-11px' }]}>
                        <Text>
                          <Text style={styles.boldText}>Bodily injury</Text> sustained by any{' '}
                          <Text style={styles.boldText}>passengers;</Text> or
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.listItemParagraph, { marginTop: '-11px' }]}>
                        <Text>
                          <Text style={styles.boldText}>Property damage</Text> arising out of any consequential loss caused by impact of the{' '}
                          <Text style={styles.boldText}>aircraft</Text> with transmission lines or pipelines.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          ),
          'Aerial Photography I': (
            <>
              <View>
                <Text>
                  This endorsement changes Item 10 of the Coverage Identification Page, THE USE OF THE AIRCRAFT, to include{' '}
                  <Text style={styles.boldText}>your</Text> use of the
                  <Text style={styles.boldText}>aircraft</Text> for aerial photography purposes for which{' '}
                  <Text style={styles.boldText}>you</Text> make a charge to others.
                </Text>
              </View>
              <View>
                <Text>
                  When the <Text style={styles.boldText}>aircraft</Text> is operated for aerial photography purposes:
                </Text>
              </View>
              <View style={styles.listContainer}>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>1. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      Only those pilots identified in Item 9 of the Coverage Identification Page, REQUIREMENTS FOR THE PILOT FLYING THE
                      AIRCRAFT, or an endorsement to this policy, will operate the <Text style={styles.boldText}>aircraft in flight</Text>{' '}
                      for such use.
                    </Text>
                  </View>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.listItemHeading}>2. &nbsp; &nbsp; &nbsp;</Text>
                  <View style={styles.listItemParagraph}>
                    <Text>
                      The following provisions of this endorsement will also apply to <Text style={styles.boldText}>your</Text> policy:
                    </Text>
                  </View>
                  <View style={styles.nestedListContainer}>
                    <View>
                      <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.listItemParagraph, { marginTop: '-11px' }]}>
                        <Text>
                          <Text style={styles.boldText}>We</Text> will not pay for physical loss of or damage cameras or photographic
                          equipment either under Part Two, AIRCRAFT PHYSICAL DAMAGE COVERAGE or Part Three, LIABILITY TO OTHERS.
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                      <View style={[styles.listItemParagraph, { marginTop: '-11px' }]}>
                        <Text>
                          <Text style={styles.boldText}>You</Text> will pay the a <Text style={styles.boldText}>property damage</Text>{' '}
                          deductible shown below with respect to each <Text style={styles.boldText}>property damage</Text> claim arising out
                          of an <Text style={styles.boldText}>occurrence</Text> while the <Text style={styles.boldText}>aircraft</Text> is
                          being used for aerial photography. <Text style={styles.boldText}>We</Text> may pay part or all of this amount to
                          effect settlement of a claim or suit and, upon notification of the action taken,{' '}
                          <Text style={styles.boldText}>you</Text> agree to reimburse us promptly for any part of this amount which was paid
                          by <Text style={styles.boldText}>us.</Text>
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.listItemParagraph,
                          { flexDirection: 'row', width: '30%', justifyContent: 'space-between', marginTop: '5px' }
                        ]}
                      >
                        <Text>Property Damage Deductible:</Text>
                        <Text>$ Nil</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          )
        }[title]
      }
    </View>
    <FooterPDF page={1} endPage={1} form={formNumber}/>
  </Page>
);

export default SpecialEndorsement;
