import React, { useEffect, useState } from 'react';

// material-ui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from 'utils/transition';

// ==============================|| ALER DIALOG SLIDE ||============================== //

export default function AlertDialogSlide({ handleResult, handleCloseDialog, open, emitValue={}, message, type="Deletion" }) {
  const [emittedValue, setEmittedValue] = useState();
  const handleClose = () => {
    handleCloseDialog();
  };

  const handleConfirmation = () => {
    handleResult(emittedValue);
    handleCloseDialog();
  };

  useEffect(() => {
    if(open){
      setEmittedValue(emitValue)
    }
  }, [emitValue, open])
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Confirm {type}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleConfirmation} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
